@tailwind base;
@tailwind components;
@tailwind utilities;

.config-main {
    @apply w-screen h-screen flex items-center justify-center px-4 flex-col bg-gradient-to-b from-blue-500 to-blue-300;
}

.title-container {
    @apply flex flex-row items-center justify-between w-full text-3xl font-bold text-white;
    height: 72px;
}

.cont {
    @apply w-full;
    height: calc(100vh - 144px);
}

.cont-in {
    @apply w-full h-full border border-blue-900 border-solid flex flex-row items-center justify-center flex flex-row items-center justify-center bg-white;
}

.left {
    @apply w-1/5 h-full border-r border-gray-400 border-solid;
}

.right {
    @apply w-4/5 h-full px-5;
}

.bottom {
    @apply w-full flex justify-end flex-row items-center;
    height: 72px;
}

.bottom-button {
    @apply bg-cgray-200 border border-solid border-cgray-600 font-bold;
    height: 56px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 18px;
}

.bottom-button:nth-child(2) {
    @apply mx-4;
}

.tabs-button {
    @apply bg-cgray-200 border-l border-solid border-cgray-300 font-bold h-full border-t relative;
    width: 20vw;
    font-size: 16px;
}

.tabs-button.selected {
    @apply bg-cgray-100 border-r border-solid border-cgray-300 font-bold h-full border-t;
}

.tabs-button.selected::after {
    @apply bg-cgray-100;
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 0;
}

.acc-row {
    @apply w-full flex flex-row items-center justify-between px-4;
    height: 48px;
    background-color: #efefef;
    border: 1px solid rgb(88, 88, 88);
    margin-bottom: 10px;
}

.acc-row input {
    @apply w-1/2 px-4;
    border: 1px solid #cdcdcd;
    height: 90%;
    font-size: 20px;
    font-weight: 700;
}