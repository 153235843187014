@font-face {
    font-family: 'icomoon';
    src:  url('./icomoon/icomoon.eot?dtcy22');
    src:  url('./icomoon/icomoon.eot?dtcy22#iefix') format('embedded-opentype'),
      url('./icomoon/icomoon.ttf?dtcy22') format('truetype'),
      url('./icomoon/icomoon.woff?dtcy22') format('woff'),
      url('./icomoon/icomoon.svg?dtcy22#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-sport-1:before {
    content: "\e966";
  }
  .icon-sport-2:before {
    content: "\e963";
  }
  .icon-sport-3:before {
    content: "\e964";
  }
  .icon-sport-4:before {
    content: "\e965";
  }
  .icon-sport-5:before {
    content: "\e967";
  }
  .icon-sport-6:before {
    content: "\e968";
  }
  .icon-sport-8:before {
    content: "\e969";
  }
  .icon-sport-11:before {
    content: "\e96a";
  }
  .icon-sport-16:before {
    content: "\e96b";
  }
  .icon-sport-17:before {
    content: "\e96c";
  }
  .icon-sport-19:before {
    content: "\e96d";
  }
  .icon-sport-22:before {
    content: "\e96e";
  }
  .icon-sport-26:before {
    content: "\e96f";
  }
  .icon-sport-29:before {
    content: "\e970";
  }
  .icon-sport-36:before {
    content: "\e971";
  }
  .icon-sport-37:before {
    content: "\e972";
  }
  .icon-sport-41:before {
    content: "\e973";
  }
  .icon-sport-42:before {
    content: "\e974";
  }
  .icon-sport-44:before {
    content: "\e975";
  }
  .icon-sport-69:before {
    content: "\e976";
  }
  .icon-sport-70:before {
    content: "\e977";
  }
  .icon-sport-74:before {
    content: "\e978";
  }
  .icon-sport-75:before {
    content: "\e979";
  }
  .icon-sport-76:before {
    content: "\e97a";
  }
  .icon-sport-77:before {
    content: "\e97b";
  }
  .icon-sport-83:before {
    content: "\e97c";
  }
  .icon-sport-87:before {
    content: "\e97d";
  }
  .icon-sport-158:before {
    content: "\e97e";
  }
  .icon-sport-185:before {
    content: "\e97f";
  }
  .icon-sport-208:before {
    content: "\e980";
  }
  