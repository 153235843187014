@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg-color: #1c1e21;
  --primary: #272a2f;
  --secondary: #464b54;
  --tertiary: #bbbbbb;
  --font-primary: #fff;
  --font-secondary: #797e85;
  --active: #5d8d03;
  --title-bg: #53565a;
  --title-text-color: #6ac5ff;
  --match-bg: #276299;
  --match-odd-bg: #276299;
  --button-h: 5.71vh;
  --button-64: 4vh;
  --button-56: 5.1851vh;
  --button-40: 3.7037vh;
  --bottom-h: calc(100vh - var(--button-h) - 0.5vh);
  --button-w: 3.5vw;
  --button-q: calc(var(--button-w) - 0.4vw);
}

body {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  user-select: none;
}

.login-container {
  @apply w-screen h-screen flex items-center justify-center bg-center bg-cover bg-no-repeat;
  background-image: url("./img/bg.jpg");
}

.machine-id-container {
  @apply absolute top-0 bg-gray-100 rounded-md px-10 flex items-center justify-center text-xl font-bold text-gray-600 hover:bg-gray-200 cursor-pointer;
  height: 96px;
}

.login-form-container {
  @apply absolute bg-gray-900 w-2/4 rounded-md px-4 py-4;
  top: 200px;
}

.login-title {
  @apply font-medium text-gray-200 text-xl mb-2;
}

.login-input-row {
  @apply flex flex-row items-center justify-between w-full;
  height: var(--button-64);
}

.login-input {
  @apply w-4/5 box-border pr-4 h-full;
}

.login-input input {
  @apply w-full h-full border-none bg-white outline-none px-4 rounded-md text-2xl placeholder-gray-600 text-gray-800;
}

.keyboard-button {
  @apply h-full bg-gradient-to-b from-gray-500 to-gray-700 w-1/5 rounded-md text-4xl text-white hover:from-gray-700 hover:to-gray-500;
}

.keyboard-button-active {
  @apply bg-gradient-to-b from-blue-400 to-blue-400;
}

.login-button {
  @apply h-full bg-gradient-to-b from-blue-500 to-blue-600 w-full rounded-md text-3xl text-white hover:from-blue-600 hover:to-blue-500 focus:from-blue-700 focus:to-blue-700 py-4 mt-4;
}

.login-button:disabled {
  @apply opacity-40 bg-gradient-to-b from-blue-400 to-blue-400;
}

.touch-keyboard-container {
  @apply w-full mt-4 rounded-md;
}

.loading-container {
  @apply w-screen h-screen fixed top-0 left-0 bg-opacity-40 bg-gray-900 z-50 flex items-center justify-center;
}

.main-layout {
  @apply w-full h-full;
  background-color: var(--bg-color);
}

.main-layout-top {
  @apply w-full flex flex-row items-center justify-between;
  background-color: var(--primary);
  height: var(--button-h);
}

.tabs {
  @apply w-8/12 h-full;
}

.controls-cont {
  @apply h-full flex flex-row items-center justify-between;
  width: 310px;
}

.controls-cont .second-tab-item {
  @apply w-2/6 h-full p-p;
}

.controls-cont .second-tab-item .second-tab-button {
  @apply w-full relative h-full bg-gradient-to-b from-cgray-400 to-cgray-600 hover:from-cgray-600 hover:to-cgray-400 cursor-pointer flex items-center justify-center flex-col;
}

.second-tab-button .icon {
  @apply w-2/5 flex relative items-center justify-center mb-1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 40%;
}

.controls-cont .second-tab-item:first-child .second-tab-button .icon {
  background-image: url("./img/icon/control-01.png");
}

.controls-cont .second-tab-item:last-child .second-tab-button .icon {
  background-image: url("./img/icon/control-03.png");
}

.controls-cont .second-tab-item:last-child .second-tab-button .text {
  font-size: 0.8vw;
  line-height: 1vw;
  font-weight: 400;
  overflow: hidden;
}
.second-tab-button .text {
  @apply font-bold text-center text-cgray-100  mt-m text-sm;
  font-size: 0.9vw;
}

.account-cont {
  @apply h-full flex flex-row items-center justify-between;
  width: 43%;
}

.account-cont .third-tab-item {
  @apply w-1/2 h-full p-p;
}
.full-width {
  width: 100%;
}

.account-cont .third-tab-item .third-tab-button {
  @apply w-full relative h-full bg-gradient-to-b from-cgray-400 to-cgray-600 hover:from-cgray-600 hover:to-cgray-400 cursor-pointer flex items-center justify-center flex-col text-lg;
}
.width100john {
  font-size: 1.06vw !important;
  width: 100% !important;
}
.account-cont .third-tab-item .third-tab-button span:first-child {
  color: yellow !important;
  line-height: 0.9vw !important;
  @apply text-cgray-100 font-bold  text-xl;
}

.account-cont .third-tab-item .third-tab-button:last-child span:last-of-type {
  @apply text-cgray-200 font-bold uppercase text-base;
  font-size: 0.9vw !important;
}

.account-cont .third-tab-item:first-child .third-tab-button span:last-of-type {
  @apply text-cgreen font-black text-xl uppercase;
}

.seperator {
  @apply h-2/3 bg-gray-600;
  width: 3px;
}

.tab-container {
  @apply w-full h-full flex flex-row items-center justify-evenly;
}

.tab-item {
  @apply h-full flex items-center justify-center p-p;
  background-color: var(--primary);
}

.tab-button {
  @apply relative w-full h-full flex items-center justify-center font-bold text-white  bg-gradient-to-b from-cgray-400 to-cgray-600 hover:from-cgray-600 hover:to-cgray-400 cursor-pointer text-center text-xl;
}

.tab-button::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}

.tab-button.live-matches-button.active {
  @apply from-livestart to-liveend;
}

.tab-button.matches-button.active {
  @apply from-matchesstart to-matchesend;
}

.tab-button.live-bingo-button.active {
  @apply from-bingostart to-bingoend;
}

.tab-button.live-casino-button.active {
  @apply from-casinostart to-casinoend;
}

.tab-button.slot-games-button.active {
  @apply from-slotstart to-slotend;
}

.tab-button.virtual-games-button.active {
  @apply from-virtualstart to-virtualend;
}

.tab-button.live-matches-button::before {
  background-image: url("./img/matches-icon.png");
}

.tab-button.matches-button::before {
  background-image: url("./img/matches-icon.png");
}

.tab-button.live-bingo-button::before {
  background-image: url("./img/tombala-icon.png");
  opacity: 0.05;
}

.tab-button.live-casino-button::before {
  background-image: url("./img/live-casino-icon.png");
  opacity: 0.1;
}

.tab-button.slot-games-button::before {
  background-image: url("./img/slot-games-icon.png");
  opacity: 0.1;
}

.tab-button.virtual-games-button::before {
  background-image: url("./img/virtual-games-icon.png");
  opacity: 0.1;
}

div.tab-item:first-child:nth-last-child(1) {
  width: 100%;
}

div.tab-item:first-child:nth-last-child(2),
div.tab-item:first-child:nth-last-child(2) ~ div {
  width: 50%;
}

div.tab-item:first-child:nth-last-child(3),
div.tab-item:first-child:nth-last-child(3) ~ div {
  width: 33.3333%;
}

div.tab-item:first-child:nth-last-child(4),
div.tab-item:first-child:nth-last-child(4) ~ div {
  width: 25%;
}

div.tab-item:first-child:nth-last-child(5),
div.tab-item:first-child:nth-last-child(5) ~ div {
  width: 20%;
}

div.tab-item:first-child:nth-last-child(6),
div.tab-item:first-child:nth-last-child(6) ~ div {
  width: 16.666%;
}

.main-layout-bottom {
  @apply w-full box-border flex flex-row items-center justify-between;
  height: var(--bottom-h);
}

.screen-layout {
  @apply w-full h-full box-border p-1;
}

.screen-layout-with-coupon {
  @apply w-10/12 h-full box-border p-1;
}

.coupon-container {
  @apply box-border p-1 relative border-l border-cgray-400 border-solid;
  height: calc(100% - 0.4vw);
  width: 17.666%;
}

.coupon-detail-container {
  @apply w-3/12 box-border p-1 relative;
  height: calc(100% - 0.4vw);
}

.lang-overlay {
  @apply w-screen h-screen fixed left-0 top-0 bg-cgray-600 bg-opacity-60 flex items-center justify-center;
}

.overlay-close {
  @apply bg-blue-400 text-white text-5xl flex items-center justify-center absolute right-2 top-2 cursor-pointer;
  width: 120px;
  height: var(--button-64);
}

.choose-lang {
  @apply flex flex-col w-3/12;
}

.choose-lang h4 {
  @apply text-3xl text-cgray-200 mb-4 font-bold uppercase;
}

.languages-cont {
  @apply w-full p-8 border border-cgray-400 rounded-md flex flex-row items-center justify-evenly flex-wrap bg-cgray-800;
}

.languages-cont .language-item {
  @apply p-4 border border-cgray-500 bg-cgray-800 hover:bg-blue-600 cursor-pointer mb-4;
}

.languages-cont .language-item .icon {
  width: 72px;
  height: 72px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
}

/* ------------------------- COUPON ------------------------- */

.coupon-cont {
  @apply w-full h-full bg-cgray-600 relative;
}

.coupon-bg-image {
  @apply w-full h-full bg-cgray-600 opacity-10 absolute top-0 left-0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./img/coupon-bg.jpg");
}

.coupon-in {
  @apply w-full h-full absolute top-0 left-0;
}

.coupon-title {
  @apply w-full relative flex items-center justify-center bg-gradient-to-b from-cgray-500 to-cgray-600 uppercase border-b border-black text-cgray-100 font-black text-lg;
  padding-right: 40px;
  height: 40px;
}

.coupon-title-detail {
  @apply w-full relative flex items-center justify-center bg-gradient-to-b from-cgray-500 to-cgray-600 uppercase border-b border-black text-cgray-100 font-black text-lg;
  height: 40px;
}
.main-window-container {
  height: 100vh; /* Occupy the full viewport height */
  display: flex; /* Optional: for flex-based layouts inside MainWindow */
  flex-direction: column; /* Adjust layout direction if necessary */
  justify-content: center; /* Center content vertically if needed */
  align-items: center; /* Center content horizontally if needed */
}

.coupon-title span {
  @apply absolute right-0 border-l border-b border-black flex items-center justify-center bg-gradient-to-b from-cgray-600 to-cgray-500 text-cgray-400;
  font-size: 24px;
  width: 40px;
  height: 40px;
}

.coupon-cont .coupon-bottom {
  @apply absolute bottom-3 p-2 w-full;
  bottom: 7.3vw !important ;
  /* jackpot duzenlemesi */
}

button.create-coupon {
  @apply w-full bg-gradient-to-b from-green-400 to-green-500 flex items-center justify-center text-lg text-white font-black uppercase;
  height: 48px;
}

button.create-coupon:disabled {
  opacity: 0.4 !important;
}

.odd-changes-check {
  @apply w-full flex flex-row items-center justify-start text-md font-medium text-cgray-200 mb-2;
  height: 40px;
}

.odd-changes-check .checkbox {
  @apply border border-cgray-800 bg-cgray-500 mr-2 flex items-center justify-center text-blue-300;
  width: 28px;
  height: 28px;
}

.coupon-bottom .bottom-row {
  font-size: 1vw;
  @apply w-full flex flex-row items-center justify-between text-gray-50;
  height: 30px;
}

.coupon-bottom .bottom-row-detail {
  @apply w-full flex flex-row items-center justify-between text-gray-50 text-md;
  height: 30px;
}

.coupon-bottom .bottom-row-detail:nth-child(odd) {
  @apply bg-cgray-700;
}

.bet-amount-cont {
  @apply w-full flex flex-row items-center justify-between;
  height: 40px;
}

.bet-amount-cont span:first-child {
  @apply text-md text-gray-50 font-bold;
}

.bet-amount {
  @apply flex flex-row items-center justify-center;
}

.bet-amount span {
  @apply flex items-center justify-center bg-gradient-to-b from-cgray-300 to-cgray-500 hover:from-gray-500 hover:to-cgray-300 text-gray-50 text-xl border border-cgray-800;
  width: 40px;
  height: 40px;
}

.bet-amount input {
  @apply border-none border-0 mx-1 bg-cgray-800 text-gray-50 font-bold text-md;
  width: 40px;
  height: 40px;
  text-align: center;
}

.amounts {
  @apply w-full flex flex-row items-center justify-between mb-2;
  height: 40px;
}

.amounts span {
  @apply flex items-center justify-center bg-gradient-to-b from-cgray-300 to-cgray-500 hover:from-gray-500 hover:to-cgray-300 text-gray-50 text-md font-bold border border-cgray-800 cursor-pointer;
  width: 40px;
  height: 40px;
}

.amounts span.active {
  @apply from-blue-500 to-blue-400 border-blue-600;
}

.amounts span:first-child {
  @apply bg-gradient-to-b from-red-400 to-red-600 bg-red-800;
}

.amounts span:last-child {
  @apply bg-gradient-to-b from-blue-400 to-blue-600 bg-blue-800;
}

.coupon-type {
  @apply w-full bg-gradient-to-b from-cgray-700 to-cgray-600 flex items-center justify-center text-md text-gray-50 border border-cgray-700 mb-2;
  height: 40px;
  letter-spacing: 1px;
}

span.empty-coupon {
  @apply text-center text-md text-cgray-300 mt-4 p-4 flex;
}

.up-down-cont {
  @apply w-full flex flex-row items-center justify-between;
}

.up-down-button {
  @apply h-full flex items-center justify-center bg-gradient-to-b from-buttonstart to-buttonend text-gray-50 hover:from-blue-500 hover:to-blue-600 cursor-pointer rounded-md;
  flex: 0.5;
}

.up-down-button:first-child {
  margin-right: 2px;
}

.up-down-button:last-child {
  margin-left: 2px;
}

.up-down-button:active {
  @apply from-blue-600 to-blue-700;
}

.up-down-button:disabled {
  @apply hover:from-buttonstart hover:to-buttonend;
  background-color: transparent !important;
  opacity: 0.4 !important;
}

.up-down-cont.size-sm {
  height: var(--button-56);
}

.up-down-cont.size-md {
  height: var(--button-64);
}

.up-down-cont.size-sm .up-down-button {
  font-size: 1.1vw;
}

.up-down-cont.size-md .up-down-button {
  font-size: 1.4vw;
}

.coupon-middle {
  @apply w-full p-2 flex items-center justify-start flex-col;
  height: calc(100% - 5rem - var(--button-64) - 406px);
}

.coupon-detail-middle {
  @apply w-full p-2 flex items-center justify-start flex-col;
  height: calc(100% - 5rem - var(--button-64) - 350px);
}

.coupon-overlay {
  @apply w-screen h-screen fixed left-0 top-0 bg-cgray-700 bg-opacity-60 flex items-center justify-end;
}

.coupon-check-overlay {
  @apply w-screen h-screen fixed left-0 top-0 bg-cgray-700 bg-opacity-60 flex items-start justify-center;
  z-index: 999999 !important;
}

.close-coupon {
  @apply w-full flex items-center justify-center bg-gradient-to-b from-red-500 to-red-600 text-white text-2xl uppercase mb-2 font-bold;
  height: 58px;
}

.coupon-overlay-left {
  @apply w-10/12 h-full flex items-center justify-center flex-col;
}

.live-detail-left {
  @apply w-10/12 h-full flex items-start justify-start flex-col bg-cgray-700;
}

.coupon-detail-overlay-left {
  @apply w-9/12 h-full flex items-center justify-center flex-col;
}

.coupon-overlay-left span:first-child {
  @apply text-5xl text-white mb-5;
}

.coupon-overlay-left span:last-child {
  @apply text-5xl text-green-400 font-bold;
}

.c-c {
  @apply w-full h-full flex flex-row items-start justify-start;
}

.l-w-arrows {
  @apply w-1/5 h-full;
}

.r-w-arrows {
  @apply w-4/5 h-full;
}

.l-w-arrows-in,
.r-w-arrows-in {
  @apply w-full;
  height: calc(var(--bottom-h) - var(--button-64) - 0.5vh);
}

.l-w-arrows-in {
  @apply px-1;
}

.r-w-arrows-in.w-sub {
  height: calc(var(--bottom-h) - var(--button-64) - var(--button-64) - 0.5vh);
}

.r-w-arrows-sub-c {
  @apply w-full flex flex-row items-center justify-between;
  height: var(--button-64);
}

.left-list-item {
  @apply w-full bg-gradient-to-b from-buttonstart to-buttonend rounded-sm flex items-center justify-start hover:from-blue-400 hover:to-blue-500 text-xl font-bold text-white cursor-pointer px-4 mb-m;
  height: var(--button-64);
  font-size: 1.25vw;
  line-height: 1.45vw;
}

.left-list-item.active {
  @apply from-blue-500 to-blue-600;
}

.right-list-item {
  @apply w-1/3;
  padding: 2px;
}

.right-list-item-in {
  @apply w-full h-full rounded-md flex flex-col items-center justify-end bg-black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
}

.right-list-item-in div {
  z-index: 999;
  margin-bottom: 10%;
}

.right-list-item-in div span.title {
  @apply text-white font-bold text-center text-xl uppercase;
}

.right-list-item-in div span.limit {
  @apply text-matchesstart font-medium text-center text-lg mt-2;
}

.right-list-item-in::after {
  @apply bg-gradient-to-b from-transparent to-black;
  content: "";
  position: absolute;
  width: 100%;
  height: 60%;
  bottom: 0;
  left: 0;
}

.sub-items-w-a {
  @apply h-full flex flex-row items-center justify-between;
  width: calc(100% - 160px);
}

.sub-items {
  @apply w-full h-full flex flex-row items-center justify-between;
}

.h-arrow {
  @apply h-full box-border;
  width: 80px;
}

.h-arrow-in {
  @apply w-full h-full bg-gradient-to-b from-buttonstart to-buttonend flex items-center justify-center text-3xl text-white cursor-pointer hover:from-matchesstart hover:to-matchesend;
}

.sub-item-c {
  @apply w-1/3 h-full box-border px-p;
}

.sub-item-c-in {
  @apply w-full h-full flex items-center justify-center text-white uppercase bg-gradient-to-b from-buttonstart to-buttonend text-xl font-bold cursor-pointer hover:from-matchesstart hover:to-matchesend;
}

.h-arrow-in:active,
.sub-item.c-in:active {
  @apply from-matchesend to-matchesstart;
}

.sub-item-c-in.active {
  @apply from-matchesstart to-matchesend;
}

.iframe-cont {
  @apply w-screen h-screen fixed top-0 left-0 bg-cgray-700 z-50 flex items-center justify-center;
}

.iframe-close {
  @apply absolute right-0 top-0 bg-gradient-to-b from-closestart to-closeend text-white hover:from-closeend hover:to-closestart cursor-pointer;
  top: 0.5vh;
  right: 0.5vh;
  width: 10vh;
  height: 9vh;
  font-size: 5vh;
}
.iframe-check {
  @apply absolute right-0 top-0 bg-gradient-to-b from-closestart to-closeend text-white hover:from-closeend hover:to-closestart cursor-pointer;
  top: 0.5vh;
  right: 11vh;
  width: 10vh;
  height: 9vh;
  font-size: 5vh;
}

.in-page-cont {
  @apply w-full h-full flex flex-col;
}

.in-page-top {
  @apply w-full flex flex-row items-center justify-between;
  height: var(--button-64);
  margin-bottom: 4px;
  margin-top: 8px;
}

.in-page-middle {
  @apply w-full flex flex-row items-center justify-between;
  height: calc(100vh);
}

.in-page-middle-left {
  @apply h-full w-1/5;
}

.in-page-middle-right-cont {
  @apply w-4/5 mb-m px-m mt-m;
  height: 100%;
}

.in-page-middle-right {
  @apply w-full overflow-y-scroll;
  height: 100%;
}

.live-in-page-middle-right {
  margin-bottom: 0px;
  height: 100vh;
  @apply w-full  overflow-y-scroll;
  /* height: calc(100% - 100px - var(--button-h)); */
  /* jackpot duzenlemesi */
}

.slive-in-page-middle-right {
  @apply w-full mb-m overflow-y-scroll;
  height: calc(100% - 58px - 0.2vw);
}

.in-page-middle-right::-webkit-scrollbar,
.live-in-page-middle-right::-webkit-scrollbar,
.slive-in-page-middle-right::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.in-page-bottom,
.live-in-page-bottom {
  @apply w-full flex flex-row items-center justify-between;
  height: var(--button-64);
}

.in-page-top-col1,
.in-page-bottom-col1 {
  @apply w-1/5 h-full flex flex-row items-center justify-between px-m;
}

.in-page-top-col3 {
  @apply w-1/12 h-full pl-m uppercase;
}

.in-page-top-col2 {
  @apply h-full flex flex-row items-center justify-between;
  width: calc(100% - 28.333333%);
}

.in-page-top-col2-mid {
  @apply h-full flex flex-row items-center justify-center pl-m w-10/12;
}
#firstarrow {
  margin-left: 10px;
  width: 6% !important;
}
#firstarrow button {
  background-image: none !important;
  background-color: #59575785;
  border: solid;
  border-color: #ffffff78;
  border-width: 2px;
}
#firstarrow button i {
  font-size: 15px;
  font-weight: 900;
}
#lastarrow {
  width: 6% !important;
  margin-right: 10px;
}
#lastarrow button {
  background-image: none !important;
  background-color: #59575785;
  border: solid;
  border-color: #ffffff78;
  border-width: 2px;
}
#lastarrow button i {
  font-size: 15px;
  font-weight: 900;
}
.in-page-bottom-col2 {
  @apply w-4/5 h-full flex flex-row items-center justify-center;
}

.c-button-cont {
  @apply h-full flex items-center justify-between box-border relative;
}

.c-button-cont:has(+ .c-button-type2) {
  margin-bottom: 0.2vw !important;
}

.in-page-top-col1 .c-button-cont:nth-child(2) {
  @apply px-m;
}

.in-page-bottom-col1 .c-button-cont:first-child {
  @apply pr-m;
}
.c-button-cont button span {
  font-size: 0.95vw;
  line-height: 0.95vw;
}
.c-button-cont button {
  font-size: 0.9vw;
  line-height: 0.95vw;
  @apply w-full h-full flex items-center justify-center bg-gradient-to-b from-buttonstart to-buttonend text-white  font-bold rounded-md hover:from-matchesstart hover:to-matchesend;
}
.bg-live .c-button-cont button {
  background-image: none !important;
  background-color: #035f06 !important;
  @apply w-full h-full flex items-center justify-center  from-buttonstart to-buttonend text-white text-lg font-bold rounded-md hover:from-matchesstart hover:to-matchesend;
}
.bg-half-time .c-button-cont button {
  background-image: none !important;
}
.c-button-cont button:active {
  @apply hover:from-matchesend hover:to-matchesstart;
}

.c-button-cont button:disabled {
  @apply from-cgray-700 to-cgray-700 hover:from-cgray-700 hover:to-cgray-700 opacity-30;
}

.c-button-cont.active button {
  @apply from-matchesend to-matchesstart;
}

.w-spe {
  @apply h-full;
  height: var(--button-64) !important;
  margin-right: 0.2vw;
  width: calc(calc(calc(67vw - 20vw) * 0.609) / 3);
}

.w-125 {
  width: 12.5%;
}

.in-page-bottom-col2 .c-button-cont {
  @apply pr-m;
}

.in-page-bottom-col2 .c-button-cont:last-child {
  @apply pr-0;
}

.search-cont {
  @apply w-full p-m relative flex items-center justify-center;
  height: var(--button-56);
}

.search-cont input {
  @apply w-full h-full rounded-sm bg-white pl-2;
}

.search-cont input::placeholder {
  @apply text-cgray-300 text-lg font-bold;
}

.search-cont i.fa-search {
  @apply text-xl absolute text-cgray-200 inline-block;
  right: 1vw;
}

.search-cont button.erase {
  @apply text-xl absolute text-cgray-200 inline-block bg-white;
  right: 0.5vw;
  width: calc(var(--button-56) - 1vh);
  height: calc(var(--button-56) - 1vh);
}

.category-cont {
  @apply w-full mb-m overflow-y-scroll;
  height: 100%;
}

.h-common {
  height: var(--button-64);
  margin-bottom: 0.1vw !important;
}

.c-button-type2 {
  @apply flex flex-row h-full px-1 relative font-alumni;
  justify-content: flex-start !important;
  font-size: 24px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
}

.c-button-type2 .img-c {
  @apply mr-3 flex items-center justify-center;
  width: calc(var(--button-w) * 0.65);
  height: calc(var(--button-w) * 0.65);
}

.c-button-type2 span.count {
  @apply absolute right-3 text-cgray-300;
  color: rgb(220, 230, 249) !important;
}

.c-button-cont.active button.c-button-type2 span.count {
  color: #fff !important;
}

.c-button-cont button:hover span.count {
  color: #fff !important;
}

.c-button-type3 {
  @apply flex flex-row px-2 relative;
  justify-content: flex-start !important;
  font-size: 1.1vw !important;
  line-height: 1.1vw !important;
  border-radius: 0 !important;
  background-image: none !important;
  background-color: #363a41;
  border-bottom: 1px solid #4a4f58;
  font-weight: 400 !important;
  height: var(--button-64) !important;
  color: rgb(28, 159, 235) !important;
}

.c-button-type3 .img-c {
  @apply flex items-center justify-center;
  width: 40px;
  height: 40px;
  font-size: 12px;
}

.c-button-cont button.c-button-type3:hover {
  color: #fff !important;
}

.c-button-type3 .img-c i {
  color: #fff !important;
}

.c-button-type3 span.count {
  @apply absolute right-3;
  font-weight: 700;
  color: rgb(28, 159, 235) !important;
}

.c-button-type3-title {
  width: calc(100% - 70px);
  text-align: left !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
  line-height: var(--button-64);
  font-size: 24px;
}

.c-button-type3.active {
  background-color: #565b64 !important;
  color: #fff !important;
}

.match-title-cont-lv {
  @apply w-full relative flex items-center;
  height: 6vh;
  margin-bottom: 0.1vw;
  margin-top: 0.1vw;
}
.match-title-cont:first-child {
  @apply w-full relative flex items-center justify-evenly;
  height: 40px;
  margin-bottom: 1px;
}

.match-title-cont:not(:first-child) {
  @apply w-full relative flex items-center justify-evenly;
  height: 20px;
  margin-bottom: 1px;
}

.t-d,
.t-s,
.t-a {
  @apply h-full flex items-center justify-center;
  width: var(--button-w);

  background-color: var(--title-bg);
  margin-right: 0.1vw;
}

.t-d img {
  width: 40%;
  height: auto;
}

.t-a {
  margin-right: 0 !important;
}

.t-o {
  @apply flex flex-col items-center justify-evenly h-full;
  background-color: var(--title-bg);
  margin-right: 7px;
  width: 240px;
}

.t-o span {
  @apply text-white font-bold;
}

.t-o-c {
  @apply flex flex-row items-center justify-between;
}

.t-o-i {
  color: rgb(255, 255, 0);
  @apply inline-block text-center font-bold;
  font-size: 0.9vw;
  flex: 1;
  line-height: 0.6vw;
}

.t-t {
  @apply h-full flex items-center justify-start font-bold  px-4;
  font-size: 15px;
  color: #e8ff4a;
  background-color: var(--title-bg);
  margin-right: 0.1vw;
  width: calc(100% - 2px - calc(9 * var(--button-w)) - 2px);
}

.t-t.i-c-11,
.m-t.i-c-20 {
  width: calc(100% - 2.4vw - calc(20 * var(--button-w)));
}

.t-t.i-c-14,
.m-t.i-c-14 {
  width: calc(100% - 1.6vw - calc(14 * var(--button-w)));
}

.t-t.i-c-12,
.m-t.i-c-12 {
  width: calc(100% - 1.6vw - calc(14 * var(--button-w)));
}

.t-t.i-c-11,
.m-t.i-c-11 {
  width: calc(100% - 1.4vw - calc(11 * var(--button-w)));
}

.t-t.i-c-10,
.m-t.i-c-10 {
  width: calc(100% - 1.2vw - calc(10 * var(--button-w)));
}

.t-t.i-c-9,
.m-t.i-c-9 {
  width: calc(100% - 1vw - calc(9 * var(--button-w)));
}

.t-t.i-c-8,
.m-t.i-c-8 {
  width: calc(100% - 1vw - calc(8 * var(--button-w)));
}

.t-t.i-c-7,
.m-t.i-c-7 {
  width: calc(100% - 1vw - calc(7 * var(--button-w)));
}

.t-t.i-c-6,
.m-t.i-c-6 {
  width: calc(100% - 0.8vw - calc(6 * var(--button-w)));
}

.t-t.i-c-5,
.m-t.i-c-5 {
  width: calc(100% - 0.8vw - calc(5 * var(--button-w)));
}

.t-t.i-c-4,
.m-t.i-c-4 {
  width: calc(100% - 0.8vw - calc(4 * var(--button-w)));
}

.t-t.i-c-3,
.m-t.i-c-3 {
  width: calc(100% - 0.8vw - calc(3 * var(--button-w)));
}

.t-t.i-c-2,
.m-t.i-c-2 {
  width: calc(100% - calc(2 * var(--button-w)));
}

.c-button-cont.uppercase button {
  /* text-transform: uppercase !important; */
}

.match-row-cont {
  @apply w-full relative flex items-center justify-evenly font-alumni;
  height: 57px;
  margin-bottom: 2px;
}

.match-row-cont-fp {
  @apply w-full relative flex items-start justify-center font-alumni;
  height: 90px;
  margin-bottom: 1px;
}

.m-d,
.m-s,
.m-a {
  @apply h-full flex flex-col items-center justify-center;
  width: var(--button-w);
  background-color: var(--title-bg);
  margin-right: 1px;
}

.m-d span {
  @apply text-white font-bold text-center;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 1px;
}

.m-a {
  margin-right: 0 !important;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 1px;
}

.m-o {
  @apply flex flex-col items-center justify-center h-full;
  background-color: var(--title-bg);
  margin-right: 6px;
}

.m-o span {
  @apply text-white font-bold;
}

.m-o-c {
  @apply flex flex-row items-center justify-between;
  height: var(--button-w);
}

.m-o-c-3 {
  width: calc(3 * var(--button-w));
}

.m-o-c-2 {
  width: calc(2 * var(--button-w));
}

.m-o-i {
  @apply inline-block text-center font-bold text-white;
  width: var(--button-w);
  height: var(--button-w);
}

.m-t {
  @apply h-full text-lg font-bold text-white relative;
  background-color: var(--match-bg);
  margin-right: 1px;
}

.m-t-2 {
  @apply h-full text-lg font-bold text-white relative flex flex-col items-center justify-between;
  background-color: var(--match-bg);
  margin-right: 1px;
}

.m-t-h {
  @apply h-full relative;
  width: calc(calc(100% - 4vw) / 2);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: calc(var(--button-q) / 2);
  font-size: 1.3vw;
  line-height: 1.3vw;
  letter-spacing: 0.05vw;
}

.red-card-home,
.red-card-away {
  @apply inline-block absolute bg-red-600 text-white text-sm flex items-center justify-center rounded-sm;
  margin-top: 3px;
  width: 15px;
  height: 20px;
  font-size: 14px;
}

.red-card-home {
  left: 1px;
}

.red-card-away {
  right: 1px;
}

.m-t-h:last-child {
  text-align: right;
}

.m-t-s {
  @apply flex items-center justify-center text-xl;
  width: 2vw;
  letter-spacing: 0.2vw;
  height: var(--button-q);
}

.m-t.i-c-odd,
.m-d.i-c-odd,
.m-a.i-c-odd,
.m-s.i-c-odd,
.m-o.i-c-odd {
  background-color: var(--match-bg);
}

.m-t.i-c-even,
.m-t-2.i-c-even,
.m-d.i-c-even,
.m-a.i-c-even,
.m-s.i-c-even,
.m-o.i-c-even {
  background-color: var(--match-odd-bg);
}

.m-o-i .c-button-cont {
  height: var(--button-w) !important;
  width: var(--button-w) !important;
}

.m-o-i .c-button-cont button {
  height: var(--button-64) !important;
  border-radius: 0 !important;
}

.m-o-i .c-button-cont button:first-child {
  margin-right: 0.1vw;
}

.m-o-i .c-button-cont button:last-child {
  margin-left: 0.1vw;
}

.m-o-i-2 {
  @apply inline-block text-center font-bold text-white;
  width: var(--button-w);
  height: calc(var(--button-w) - 0.4vw);
}

.m-o-i-2 .c-button-cont {
  height: calc(var(--button-w) - 0.2vw) !important;
  width: var(--button-w) !important;
}

.m-o-i-2 .c-button-cont button {
  height: calc(var(--button-64) - 0.1vw) !important;
  border-radius: 0 !important;
}

.m-o-i-2 .c-button-cont button:first-child {
  margin-right: 0.1vw;
}

.m-o-i-2 .c-button-cont button:last-child {
  margin-left: 0.1vw;
}

.m-s .c-button-cont,
.m-s .c-button-cont button,
.m-a .c-button-cont,
.m-a .c-button-cont button {
  @apply w-full;
  border-radius: 0 !important;
}

.t-o span:first-child {
  font-size: 60% !important;
  text-align: center;
  display: inline-block;
}

/* .match-row-cont-fp .m-t, .match-row-cont-fp .m-o {
    height: var(--button-64);
} */

.keyboard-close {
  background-color: red !important;
  font-weight: 700 !important;
  color: #fff !important;
}

.keyboard-clear {
  background-color: red !important;
  font-weight: 700 !important;
  color: #fff !important;
  width: 4% !important;
}

.keyboard-search {
  background-color: #5d8d03 !important;
  font-weight: 700 !important;
  color: #fff !important;
}

.hg-theme-default .hg-button {
  height: 8vh !important;
  font-size: 2vh !important;
  font-weight: 700 !important;
}

.hg-theme-default .hg-row .hg-button:not(:last-child) {
  margin-right: 0.5vw !important;
}

.hg-theme-default {
  padding: 0.5vw !important;
  background-color: #646971 !important;
  font-family: "Oswald", "Arial", "sans-serif" !important;
}

.hg-theme-default .hg-row:not(:last-child) {
  margin-bottom: 0.5vw !important;
}

.keyboard-caps {
  background-color: #797e85 !important;
  font-weight: 700 !important;
  color: #fff !important;
}

.search-overlay {
  @apply w-screen h-screen fixed left-0 top-0 bg-cgray-600 bg-opacity-60 flex items-center justify-center flex-col;
}

.search-overlay-input {
  @apply w-full bg-white rounded-md flex items-center justify-start mb-4 px-10 text-3xl font-bold;
  height: 8vh;
}

.m-s button i {
  @apply text-3xl text-cgray-200;
}

.m-a button {
  @apply w-full h-full font-bold;
  color: #fff;
}

.t-o-2 {
  width: calc(var(--button-w) * 2 - 2px) !important;
}

.t-o-3 {
  width: calc(var(--button-w) * 3 - 2px) !important;

  width: calc(var(--button-w) * 3 + 5px) !important;
}

.t-o-4 {
  width: calc(var(--button-w) * 4 + 4px) !important;
}

.t-o-5 {
  width: calc(var(--button-w) * 5 - 2px) !important;
}

.detail-left {
  @apply w-1/3 h-full;
}

.detail-right {
  @apply w-2/3 h-full;
}

.statistic-cont {
  @apply w-full bg-cgray-400 flex items-center justify-between h-full;
}

.match-detail-title {
  @apply w-full bg-cgray-900 flex items-start justify-center flex-col px-4;
  height: 10vh;
}

.match-detail-title p:first-child {
  @apply text-lg text-cgray-400;
}

.match-detail-title p:nth-child(2) {
  @apply font-bold text-2xl text-cgray-300 my-1;
}

.match-detail-title p:last-child {
  @apply text-lg font-bold text-cgray-400;
}

.match-detail-bottom {
  @apply w-full flex items-center justify-center;
  height: var(--button-64);
}

.match-detail-cont {
  @apply w-full p-2;
  height: calc(100% - var(--button-64) - 10vh);
  overflow: scroll;
}

.match-detail-cont::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.detail-odds-cont {
  @apply w-full p-2 bg-cgray-500 mb-4;
  border-bottom: 1px solid var(--primary);
}

.detail-odd-title {
  @apply text-cgray-100 font-bold text-lg;
}

.detail-odds {
  @apply w-full flex flex-row items-center justify-start flex-wrap mt-2;
}

.detail-odd-item {
  @apply w-1/3 px-m mb-2;
  height: var(--button-56);
}

.detail-odd-item:first-child:nth-last-child(1) {
  width: 100%;
  margin-bottom: 0 !important;
}

.detail-odd-item:first-child:nth-last-child(2),
.detail-odd-item:first-child:nth-last-child(2) ~ div {
  width: 50%;
  margin-bottom: 0 !important;
}

.detail-odd-item:first-child:nth-last-child(3),
.detail-odd-item:first-child:nth-last-child(3) ~ div {
  width: 33.3333%;
  margin-bottom: 0 !important;
}

.detail-odd-item:first-child:nth-last-child(4),
.detail-odd-item:first-child:nth-last-child(4) ~ div {
  width: 25%;
  margin-bottom: 0 !important;
}

.detail-odd-item:first-child:nth-last-child(5),
.detail-odd-item:first-child:nth-last-child(5) ~ div {
  width: 20%;
  margin-bottom: 0 !important;
}

.detail-odd-item:first-child:nth-last-child(6),
.detail-odd-item:first-child:nth-last-child(6) ~ div {
  width: 16.666%;
  margin-bottom: 0 !important;
}

.detail-odd-item-button {
  @apply w-full h-full flex items-center justify-between bg-cgray-700 hover:bg-blue-500 text-lg font-bold text-white cursor-pointer px-4;
}

.detail-odd-item-button-in-coupon {
  @apply w-full h-full flex items-center justify-between bg-closestart text-lg font-bold text-white cursor-pointer px-4;
}

.detail-odd-item-button span:first-child {
  max-width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.detail-odd-item-button-in-coupon span:first-child {
  max-width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.odd-button {
  @apply relative w-full h-full;
}

.odd-button:disabled {
  @apply flex items-center justify-center;
  position: relative;
  opacity: 0.5;
  background-color: var(--primary);
  background-image: none !important;
  height: var(--button-w) !important;
  width: var(--button-w) !important;
}

.odd-button:disabled::after {
  display: flex;
  align-items: center;
  justify-content: center;
  content: "\f023";
  width: 100%;
  height: 100%;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.4vw;
}

.live-in-page-top-col1 {
  width: 100%;
  @apply h-full flex flex-row items-center justify-between pr-m;
}

.live-in-page-top-col2 {
  width: 100%;
  @apply h-full flex flex-row items-center justify-between;
}

.w-spe2 {
  @apply h-full;
  height: var(--button-64) !important;
  margin-right: 0.2vw;
  width: calc(calc(calc(100vw - 20vw) * 0.609) / 3);
}

.live-in-page-top-col2 .c-button-cont:nth-child(2) {
  margin-right: 0.2vw !important;
  margin-left: 0.2vw !important;
}

.live-pagination-cont {
  @apply w-1/5 h-full flex flex-row items-center justify-center bg-cgray-700 border border-cgray-400 mx-m text-closestart font-bold text-xl;
}

.live-in-page-bottom .c-button-cont:last-child {
  @apply ml-m;
}

.all-bulletin-cont {
  @apply w-1/4 h-full mr-m px-4 bg-gradient-to-b from-buttonstart to-buttonend rounded-sm;
}

.all-bulletin-cont.selected {
  @apply from-matchesstart to-matchesend;
}

.all-bulletin-check {
  @apply w-full h-full flex flex-row items-center justify-start text-white text-lg font-bold cursor-pointer uppercase;
}

.all-bulletin-check .check {
  @apply bg-blue-900 mr-4 border border-cgray-300 flex items-center justify-center relative text-md;
  width: var(--button-40);
  height: var(--button-40);
}

.live-in-page-middle-right::-webkit-scrollbar {
  display: none; /* Chrome, Safari ve Opera için */
}
.live-in-page-middle-right {
  scrollbar-width: none; /* Firefox için */
}
#favaltbut {
  display: none;
  position: absolute;
  width: 100px;
  height: 60px;
  border-radius: 200px;
  bottom: 20px;
  left: 320px;
  z-index: 999999;
  background-color: #2c2f2f;
  background-image: none !important;
  border: solid;
  border-color: white;
  border-width: 1px;
}

#upaltbut {
  position: absolute;
  width: 50px;
  height: 40px;
  border-radius: 7px;
  bottom: 10px;
  left: 190px;
  z-index: 999999;
  background-color: #2c2f2f;
  background-image: none !important;
  border: solid;
  border-color: white;
  border-width: 1px;
  opacity: 0.6; /* %20 transparan */
  transition: opacity 0.3s; /* Hover geçiş animasyonu */
}

#upaltbut:hover {
  opacity: 1; /* Hover'da %100 opak */
}

#downaltbut {
  position: absolute;
  width: 50px;
  height: 40px;
  border-radius: 7px;
  bottom: 10px;
  left: 260px;
  z-index: 999999;
  background-color: #2c2f2f;
  background-image: none !important;
  border: solid;
  border-color: white;
  border-width: 1px;
  opacity: 0.6; /* %20 transparan */
  transition: opacity 0.3s; /* Hover geçiş animasyonu */
}
#downaltbut:hover {
  opacity: 1; /* Hover'da %100 opak */
}
#downaltbut button i {
  font-size: 20px;
  color: white;
}
#upaltbut button i {
  font-size: 20px;
  color: white;
}
#favaltbut button {
  background-image: none !important;
}
#upaltbut button {
  background-image: none !important;
}
#downaltbut button {
  background-image: none !important;
}
.live-in-page-middle {
  padding-right: 1px;
  margin-bottom: -35px;
  @apply w-full flex flex-col items-center justify-between;
  height: calc(98vh - var(--button-64) - var(--button-64));
}

.second-blink {
  animation: blink-animation 1s steps(2, start) infinite;
  -webkit-animation: blink-animation 1s steps(2, start) infinite;
}

@keyframes blink-animation {
  to {
    opacity: 0;
  }
}

@-webkit-keyframes blink-animation {
  to {
    opacity: 0;
  }
}

.m-d.continue {
  @apply bg-green-500;
}

.m-d.half-time {
  @apply bg-blue-500;
}

.m-d.stopped {
  @apply bg-red-400;
}

/* -------------------------------- COUPON ITEM ------------------------------- */

.coupon-item-cont {
  @apply w-full mb-2 flex flex-row items-center justify-between pb-2 border-b border-solid border-cgray-300;
}

.coupon-item-left {
  width: calc(100% - 2.5vw);
}

.coupon-detail-item-left {
  width: 100%;
}

.coupon-item-right {
  @apply flex flex-col items-start justify-start;
  width: 2vw;
  min-height: 4.2vw;
}

.coupon-item-sportname {
  @apply w-full text-cgray-200 font-alumni font-medium;
  font-size: 16px;
}

.coupon-item-teams {
  @apply w-full flex flex-row items-center justify-start font-semibold text-cgray-100 font-alumni;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.coupon-item-odd {
  @apply w-full flex flex-row items-center justify-between font-medium text-cgray-100 font-alumni;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.coupon-item-delete,
.coupon-item-banko {
  @apply flex items-center justify-center;
  width: 2vw;
  height: 2vw;
}

.coupon-item-delete {
  @apply bg-red-600 text-white text-md mb-m;
}

.coupon-item-banko {
  @apply bg-cgray-400 text-cgray-300 font-black text-xl;
}

.coupon-item-banko.selected {
  @apply bg-blue-400 text-blue-100;
}

.coupon-types-cont {
  @apply absolute w-full h-full top-0 left-0 bg-cgray-600 bg-opacity-80 flex flex-col items-center justify-center p-2;
}

button.confirm-coupon-types {
  @apply w-full bg-gradient-to-b from-bingostart to-bingoend flex items-center justify-center text-lg text-white font-black uppercase mb-2;
  height: 48px;
}

button.close-coupon-types {
  @apply w-full bg-gradient-to-b from-bingostart to-bingoend flex items-center justify-center text-lg text-white font-black uppercase;
  height: 48px;
}

.combination-item {
  @apply w-full flex flex-row items-center justify-between px-2 bg-cgray-100 mb-2;
  height: 48px;
}

.combination-item.selected {
  @apply bg-blue-200;
}

.combination-item span:first-child {
  @apply text-md font-bold text-cgray-800 uppercase;
}

.combination-item span:last-child {
  @apply bg-cgray-200 text-cgray-600 text-md font-bold flex items-center justify-center;
  width: calc(48px - 1vw);
  height: calc(48px - 1vw);
}

.combination-item.selected span:last-child {
  @apply bg-cgray-100;
}

/* -------------------------------- COUPON ITEM ------------------------------- */

/* -------------------------------- TICKET CHECK ------------------------------- */

.ticket-check-tab {
  @apply flex flex-row items-center justify-between mb-4 rounded-lg overflow-hidden;
}

.ticket-check-tab-item {
  @apply w-1/2 bg-cgray-400 flex items-center text-white font-bold text-xl justify-center cursor-pointer;
  height: var(--button-64);
}

.ticket-check-tab-item.selected {
  @apply bg-matchesend;
}

.ticket-result-cont {
  @apply absolute right-0 top-m bg-cgray-100;
  width: calc(100% - 25% - 0.6vw);
  height: calc(100% - 0.4vw);
}

.ticket-results {
  @apply w-full p-2;
  height: calc(100% - var(--button-64) - var(--button-64));
  overflow: scroll;
}

.ticket-result-title {
  @apply w-full bg-buttonend flex items-center justify-center text-xl font-bold text-white;
  height: var(--button-64);
}

.bingo-ticket {
  @apply w-full flex flex-row flex-wrap items-start justify-start rounded-lg;
}

.bingo-ticket-number {
  --n-w: calc(100% / 9 - 11px);
  @apply flex items-center justify-center p-2;
  width: var(--n-w);
  height: 96px;
}

.bingo-ticket-number-in {
  @apply w-full h-full flex items-center justify-center bg-white rounded-lg border border-solid border-cgray-700 text-2xl font-black;
}

.bingo-ticket-number-in-empty {
  @apply w-full h-full flex items-center justify-center bg-white rounded-lg border border-solid border-white bg-opacity-30;
}

.bingo-table-cont {
  @apply w-1/2 p-1;
}

.bingo-table-col {
  @apply w-full flex flex-row items-center justify-between;
  height: var(--button-64);
}

.bingo-col-1 {
  @apply w-1/3 h-full bg-cgray-300 flex items-center justify-end flex-row px-2 text-lg font-bold text-black;
}

.bingo-col-2 {
  @apply w-2/3 h-full bg-cgray-200 flex items-center justify-start flex-row px-2 text-lg font-medium text-black;
}

.sell-coupon-cont,
.cancel-coupon-cont,
.pay-coupon-cont {
  @apply w-full p-1 border border-solid border-cgray-300 flex flex-row items-center justify-start mb-2;
}

.sell-coupon-cont div {
  @apply px-8 py-4 bg-livestart text-white font-bold text-lg mr-4;
}

.pay-coupon-cont div {
  @apply px-8 py-4 bg-livestart text-white font-bold text-lg mr-4;
}

.cancel-coupon-cont div {
  @apply px-8 py-4 bg-closeend text-white font-bold text-lg mr-4;
}

.sell-coupon-cont span {
  @apply text-lg text-black;
}

.ticket-result-table {
  @apply w-full mb-4;
}

.ticket-result-table thead {
  @apply bg-cgray-300;
  height: var(--button-56);
}

.ticket-result-table tbody tr {
  @apply bg-gray-100;
  height: var(--button-64);
}

.ticket-result-table tbody tr:nth-child(odd) {
  @apply bg-gray-50;
}

.ticket-result-table tbody tr td {
  vertical-align: middle;
  text-align: center;
}

.ticket-result-table tbody tr td.canceled {
  background-color: #ffab75;
}

.ticket-result-table tbody tr td.opened {
  background-color: #dff3ff;
}

.ticket-result-table tbody tr td.win {
  background-color: #cfffca;
}

.ticket-result-table tbody tr td.lose {
  @apply bg-bingostart;
}

/* -------------------------------- TICKET CHECK ------------------------------- */

/* -------------------------------- MANAGER PAGE ------------------------------- */

.admin-password-input {
  @apply w-full h-full flex flex-col items-start justify-center;
}
.reset-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.admin-password-input span:first-child {
  @apply text-md mb-m;
}

.manager-tabs {
  @apply w-full bg-gradient-to-b from-buttonstart to-buttonend flex flex-row items-center justify-between;
  height: var(--button-64);
}

.manager-tab-item {
  @apply w-1/4 h-full flex flex-row items-center justify-center text-xl text-white font-bold cursor-pointer;
  border-bottom: 0.2vw solid transparent;
}

.manager-tab-item:hover {
  @apply border-matchesend text-matchesend;
}

.manager-tab-item.active {
  @apply border-matchesstart text-matchesstart;
}

.manager-tab-item:last-child {
  @apply bg-closeend;
}

.manager-tab-item:last-child:hover {
  @apply border-closeend text-closestart;
}

.manager-tab-item i {
  @apply mr-4;
}

.manager-content {
  @apply w-full;
  height: calc(100% - var(--button-64) - var(--button-56) - 0.4vw);
}

.accounts-title {
  @apply w-full bg-cgray-400 flex items-center justify-start flex-row text-xl font-bold text-white px-8;
  height: var(--button-64);
}

.reset-button {
  @apply px-4 py-2 bg-slotend border border-solid border-cgray-800 rounded-lg text-md text-cgray-800;
}

.print-button {
  @apply px-4 py-2 bg-matchesend border border-solid border-blue-500 rounded-lg text-md text-white;
}

.detail-button {
  @apply px-4 py-2 bg-cgray-700 border border-solid border-cgray-500 rounded-lg text-md text-white;
}

.bottom-pagination-cont {
  @apply w-full flex items-center flex-row justify-between;
  height: var(--button-56);
}

.st-cancel,
.st-lost,
.st-won,
.st-waiting,
.st-in,
.st-out,
.st-cancel td,
.st-lost td,
.st-won td,
.st-waiting td,
.st-in td,
.st-out td {
  @apply border border-solid border-cgray-600 text-md font-bold;
}

.st-cancel {
  background-color: #ffab75 !important;
}

.st-lost,
.st-out {
  background-color: #ffd5d5 !important;
}

.st-won,
.st-in {
  background-color: #cfffca !important;
}

/* -------------------------------- MANAGER PAGE ------------------------------- */

.button-sport-icon {
  @apply text-3xl mr-4;
}

.in-page-layout-1 {
  width: calc(83% - 0.4vw) !important;
  margin-right: 0.2vw !important;
  height: 95vh;
}

.in-page-cont-2 {
  @apply w-full h-full flex flex-row items-center justify-between box-border;
}

.static-coupon-container {
  @apply h-full box-border relative;
  width: 17%;
}

.coupon-middle-2 {
  @apply w-full p-2 flex items-center justify-start flex-col;
  height: calc(100% - 11.5rem - 250px - 0.4vw);
  /* jackpot duzenlemesi */
}

.in-page-top-col2-mid-2 {
  @apply h-full flex flex-row items-center justify-center pl-m w-10/12;
}

.in-page-top-col2-2 {
  @apply h-full flex flex-row items-center justify-between;
  width: calc(100% - 20.2%);
}

.w-spe2 {
  @apply h-full;
  height: var(--button-64) !important;
  margin-right: 0.2vw;
  width: calc(calc(calc(67vw - 20vw) * 0.609) / 3);
}

.w-full-force {
  width: 100% !important;
}

.live-in-page-top-col1-2 {
  width: 100% !important;
  @apply h-full flex flex-row items-center justify-between pr-m;
}

.live-in-page-top-col2-2 {
  @apply w-1/5 h-full flex flex-row items-center justify-between;
}

.live-in-page-top-col2-2 .c-button-cont:first-child {
  margin-right: 0.2vw !important;
}

.coupon-numpad-cont {
  bottom: 112px !important;
  @apply w-full absolute bottom-0 p-1 bg-cgray-700;
}

.coupon-numpad-input {
  @apply w-full bg-white rounded-md flex items-center justify-start mb-m px-4 text-3xl font-bold;
  height: 8vh;
}

.live-tab-select-cont {
  @apply w-full absolute left-0;
  height: 67.5px;
  top: -0.5vw;
}

.live-tab-select {
  @apply w-full absolute right-0 z-50 top-0;
  display: none;
}

.live-tab-select-item {
  @apply w-full bg-cgray-400 flex flex-col items-center justify-center text-md text-white font-bold cursor-pointer;
  height: 40px;
}

.live-tab-select-item:hover {
  @apply text-closestart;
}

.live-tab-select-cont:hover .live-tab-select {
  display: block;
}

.live-match-fh-name {
  @apply w-full flex flex-row items-center justify-end;
  /* height: calc(var(--button-w) - 0.0vw); */
  height: inherit;
}

.live-row-tournament {
  @apply px-2 py-1 bg-cgray-400 text-white font-bold flex items-center flex-row justify-start rounded-tr-full rounded-br-full;
  font-size: 0.7vw;
}

.live-row-tournament img {
  @apply mr-4;
  height: 24px;
  width: auto;
}

.live-row-priod-name {
  @apply px-4 py-1 bg-cgray-400 text-white font-bold flex items-center flex-row justify-start rounded-tl-full rounded-bl-full;
  font-size: 0.7vw;
}

.market-animate-dec,
.market-animate-inc {
  @apply flex items-center justify-center absolute;
  right: 0;
  bottom: 1px;
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  width: calc(var(--button-w) - 1px);
}

/* .market-animate-inc {
    @apply bg-gradient-to-b from-transparent to-cgreen;
}

.market-animate-dec {
    @apply bg-gradient-to-b from-transparent to-closeend;
} */

.market-animate-inc::after {
  content: "\f077";
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  color: greenyellow;
}

.market-animate-dec::after {
  content: "\f078";
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  color: red;
}

.odd-group {
  @apply flex flex-row items-center justify-between;
  height: 40px;
}

.odd-group-3 {
  width: calc(var(--button-w) * 3);
}

.odd-group-2 {
  width: calc(var(--button-w) * 2);
}

.live-odd-button-cont {
  @apply flex items-center justify-center relative;
  width: var(--button-w);
  height: 57px;
}

.live-odd-button-cont:nth-child(2) {
  margin-left: 1px;
  margin-right: 1px;
}

.live-odd-button-cont div {
  font-size: 20px !important ;
  @apply h-full w-full flex items-center justify-center cursor-pointer font-bold text-white;
}

.odd-button-cont {
  @apply flex items-center justify-center relative;
  width: var(--button-w);
  height: 40px;
}

.odd-button-cont:nth-child(2) {
  margin-left: 0.05vw;
  margin-right: 0.05vw;
}

.odd-button-cont div {
  @apply h-full w-full flex items-center justify-center cursor-pointer text-lg font-bold text-white;
}

.live-match-teams-score {
  @apply w-full flex flex-col items-center justify-between px-4 pt-m;
  height: 57px;
}

.live-match-teams {
  @apply w-full flex flex-row items-center justify-between h-3/5 pb-1;
}

.live-match-teams-flag {
  @apply w-full flex flex-row items-center justify-between h-2/5;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 1.1vw;
  font-weight: 300;
}

.live-match-teams-flag img {
  /* @apply ml-2; */
  margin-left: -5px;
  width: 18px;
  height: auto;
}

.live-match-teams-flag span:last-child {
  @apply flex flex-row;
}

.live-match-teams-home,
.live-match-teams-away {
  @apply relative;

  overflow: hidden;
  font-size: 1vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  font-weight: 600;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  white-space: nowrap;
  height: 23px;
}

.live-match-teams-away {
  text-align: right;
}

.live-match-score {
  @apply absolute top-0 flex items-end justify-center font-bold;
  left: calc((100% - 6vw) / 2);
  height: 40px;
  width: 6vw;
  font-size: 32px;
  line-height: 32px;
  padding-bottom: 5px;
  /* letter-spacing: 1px; */
  text-align: center;
}

.live-match-favorite {
  @apply flex items-end justify-center;
  font-size: 15px;
  margin-left: -15px;
  margin-right: 10px;
}
.categoryNameMatch {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  text-align: left;
  color: #b9b9b9;
  background: rgba(0, 0, 0, 0.2);
  padding: 5px 10px 5px 17px;
  border-radius: 0 5px 5px 0;
  font-weight: 400;
  font-size: 14px;
  margin-left: -18px;
}
.live-odd-button-cont.bg-not-started .odd-special {
  background-color: #084880;
}
/* SECOND SCREEN LIVE MATCHES */

.s-t-o-3 {
  width: calc(var(--button-w) * 3 + 0.1vw) !important;
}

/* SECOND SCREEN LIVE MATCHES goksal duzenledi */

.live-odd-button-cont div.active-odd {
  @apply border-solid border-r border-cgray-800  from-buttonstart to-buttonend;
}

div.odd-special {
  @apply border-solid border-r border-cgray-800;
  font-size: 24px !important;
}

.bg-half-time {
  background-color: #636308 !important;
}

.bg-half-time .odd-group .live-odd-button-cont .active-odd {
  @apply from-bghalftimestart to-bghalftimeend;
}

.bg-half-time .odd-group .live-odd-button-cont .odd-special {
  background-image: none !important;
  background-color: #636308 !important;
  color: #ffff00 !important;
}

.bg-half-time .live-match-teams-score .live-match-teams .live-match-score {
  color: #ffff00 !important;
}

.bg-live {
  background-color: #469928 !important ;
}
.bg-livea {
  background-color: #097f05 !important;
}

.bg-live .odd-group .live-odd-button-cont .active-odd {
  @apply from-livestart to-liveend;
}

.bg-live .odd-group .live-odd-button-cont .odd-special {
  background-image: none !important;
  background-color: #035f06 !important;
  color: #00ff00 !important;
}

.bg-live .live-match-teams-score .live-match-teams .live-match-score {
  color: #00ff00 !important;
}

.bg-not-started .odd-group .live-odd-button-cont .active-odd {
  @apply from-buttonstart to-buttonend;
}
.bg-not-started .live-match-teams-score {
  background-color: #084880 !important;
}
.bg-not-started .c-button-cont button {
  background-image: none !important;
  background-color: #084880 !important;
}
.bg-not-started .odd-group .live-odd-button-cont .odd-special {
  background-color: #084880;
  @apply from-buttonend to-buttonend;
}

div.active-odd:hover,
.bg-half-time .odd-group .live-odd-button-cont .active-odd:hover,
.bg-not-started .odd-group .live-odd-button-cont .active-odd:hover,
.bg-live .odd-group .live-odd-button-cont .active-odd:hover {
  @apply from-matchesstart to-matchesend;
}

div.active-odd:active,
.bg-half-time .odd-group .live-odd-button-cont .active-odd:active,
.bg-not-started .odd-group .live-odd-button-cont .active-odd:active,
.bg-live .odd-group .live-odd-button-cont .active-odd:active {
  @apply from-matchesend to-matchesstart;
}

div.active-odd.selected,
.bg-half-time .odd-group .live-odd-button-cont .active-odd.selected,
.bg-not-started .odd-group .live-odd-button-cont .active-odd.selected,
.bg-live .odd-group .live-odd-button-cont .active-odd.selected {
  @apply from-closestart to-closeend;
}

div.passive-odd {
  @apply bg-red-700;
}

div.locked-odd {
  @apply relative bg-opacity-60 opacity-40 border-solid border-r border-cgray-800 text-cgray-800;
}

div.semi-locked-odd {
  @apply relative bg-opacity-60 opacity-20 bg-cgray-800 border-solid border-r border-cgray-800;
}

div.locked-odd::after {
  display: flex;
  align-items: center;
  justify-content: center;
  content: "\f023";
  width: 100%;
  height: 100%;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1vw;
}

.active-odd {
  font-size: 22px !important;
  line-height: 22px;
  letter-spacing: 1px;
}

.active-odd.in-coupon {
  @apply bg-closestart;
  background-image: none !important;
}

.live-detail-title {
  @apply w-full flex flex-row items-center justify-between px-4;
  height: 58px;
  background-color: #4dae50;
}

.live-detail-title-col {
  @apply w-1/3 flex flex-row items-center text-xl text-white font-alumni font-bold;
  font-size: 30px;
}

.live-detail-title-col:nth-child(2) {
  justify-content: center;
}

.live-detail-title-col:last-child {
  justify-content: flex-end;
}

.live-detail-title-col img {
  @apply mr-2;
  height: 30px;
  width: auto;
}

.live-statistic-cont {
  @apply flex flex-col ml-2 items-center bg-gradient-to-b from-cgray-600 to-cgray-500;
  width: calc(100% - 1vw);
}

.live-statistic-title {
  @apply w-full p-4 flex flex-row items-center justify-between bg-gradient-to-b from-cgray-900 to-cgray-800 text-white text-lg font-bold;
}

.live-statistics-area {
  @apply bg-bgcolor w-1/2;
  height: 32.5vw;
}

.live-statistics-area-2 {
  @apply bg-bgcolor w-2/3;
  height: 25.5vw;
}

.live-statistics-area-3 {
  @apply bg-bgcolor w-full;
  height: 20vw;
}

.live-matches-left-b {
  @apply w-full overflow-x-auto;
  height: calc(100vh - 58px - 1.2vw - var(--button-64));
}

.classic-stat-cont {
  @apply w-full flex items-center justify-center py-4;
  background-color: #162337;
  background-image: url("./img/stat-bg/football.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.classic-stat-cont.type-3 {
  background-image: url("./img/stat-bg/basketball.jfif");
}

.classic-stat-cont.type-4 {
  background-image: url("./img/stat-bg/tennis.jfif");
}

.classic-stat-cont.type-5 {
  background-image: url("./img/stat-bg/volleyball.jfif");
}

.classic-stats {
  @apply w-11/12 flex flex-col items-center justify-center;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 40px 30px -23px rgb(0 0 0 / 75%);
  padding-bottom: 0.5vw;
}

.classic-stats-title {
  @apply flex flex-row w-full items-center justify-between px-4 mb-2;
  background-color: #373269;
  height: 30px;
}

.classic-stats-row {
  @apply flex flex-row w-full items-center justify-between px-4;
}

.classic-stats-separator {
  @apply w-full bg-cgray-400 my-2;
  height: 1px;
}

.classic-stats .team {
  @apply text-white text-lg w-1/2;
}

.title-col,
.row-col {
  @apply text-center text-lg text-white flex items-center justify-center relative;
  width: 7.5%;
}

.yellow-card::after {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("./img/yellow-card.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.red-card::after {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("./img/red-card.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.corner::after {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("./img/corner.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.classic-stat-cont.type-1 {
  background-image: url("./img/stat-bg/football.jpg");
}

.market-tabs {
  @apply flex w-full items-center justify-start bg-cgray-800;
  height: var(--button-56);
}

.market-tabs-favorite,
.market-tabs-plus {
  @apply bg-cgray-600 flex items-center justify-center text-yellow-500 text-lg;
  width: var(--button-56);
  height: var(--button-56);
}

.market-tabs-favorite.selected {
  @apply bg-yellow-500 text-white;
}

.market-tabs-plus {
  @apply text-white;
}

.market-tabs-middle {
  @apply h-full flex flex-row mx-m overflow-y-auto;
  width: calc(100% - var(--button-56));
}

.market-tab-item {
  @apply h-full flex items-center justify-center bg-cgray-600 border border-solid border-cgray-900 text-white mr-m font-semibold text-lg cursor-pointer;
  min-width: calc(85vw / 5);
}

.market-tab-item:last-child {
  margin-right: 0 !important;
}

.market-tab-item:hover {
  @apply bg-cgray-400;
}

.market-tab-item.selected {
  @apply bg-matchesstart;
}

.market-groups {
}

.market-group {
  @apply w-full;
}

.market-group-in {
  @apply w-full border border-solid border-cgray-800;
}

.market-group-title {
  @apply w-full px-4 flex flex-row items-center justify-between bg-cgray-600 text-white text-lg font-bold uppercase;
  height: var(--button-56);
}

.market-group-odds {
  @apply p-2 bg-cgray-900 flex flex-row items-start justify-start flex-wrap;
}

.market-group-odd {
  @apply flex flex-row items-center justify-between bg-cgray-800 mb-1 cursor-pointer font-alumni font-semibold;
  height: var(--button-56);
  font-size: 32px;
  letter-spacing: 1px;
}

.market-group-handicap {
  @apply flex flex-row items-center justify-between mb-1 cursor-pointer w-1/4 pr-1 box-border;
  height: var(--button-56);
}

.market-group-handicap-in {
  @apply bg-cgray-800 w-full h-full flex items-center justify-center font-alumni font-bold text-white;
  font-size: 22px;
  letter-spacing: 1px;
}

.market-group-odds.group-2 .market-group-odd {
  @apply w-1/2;
}

.market-group-odds.group-3 .market-group-odd {
  @apply w-1/3;
}

.market-group-odds.group-4 .market-group-odd {
  @apply w-1/4;
}

.market-group-odds.group-1 .market-group-odd {
  @apply w-full;
}

.market-group-odd:hover {
  @apply bg-cgray-700;
}

.market-group-odd.odd-locked {
  @apply opacity-70;
}

.market-group-odd.in-coupon .odds {
  @apply bg-closestart;
}

.market-group-odd .outcome {
  @apply h-full pl-4 font-semibold flex items-center;
  width: calc(100% - calc(var(--button-64) * 1.5));
  color: #6ac5ff;
  font-size: 1.1vw;
}

.market-group-odd .odds {
  @apply h-full pl-1 text-white font-bold bg-buttonstart flex items-center justify-center relative;
  width: calc(var(--button-64) * 1.5);
  font-size: 1.2vw;
}

.market-group-odd .odds .market-animate-inc,
.market-group-odd .odds .market-animate-dec {
  width: 100% !important;
}

.coupon-odd-animate {
  color: greenyellow;
  font-size: 0.5vw;
  padding-top: 0.2vw;
}

.coupon-odd-animate.dec {
  color: red;
}

.touch-device .bg-live .odd-group .live-odd-button-cont .active-odd:hover {
  @apply from-livestart to-liveend;
}

.touch-device .bg-half-time .odd-group .live-odd-button-cont .active-odd:hover {
  @apply from-bghalftimestart to-bghalftimeend;
}

.touch-device
  .bg-not-started
  .odd-group
  .live-odd-button-cont
  .active-odd:hover {
  @apply from-buttonstart to-buttonend;
}

.touch-device .bg-not-started.live-odd-button-cont .active-odd:hover {
  @apply from-buttonstart to-buttonend;
}

.touch-device .c-button-cont:not(.active) button:hover {
  @apply from-buttonstart to-buttonend;
}

.match-row-cont-fp .m-o .sep {
  @apply w-full bg-cgray-800;
  height: 1px;
}

.active-odd {
  font-size: 1.2vw !important;
}

.red-card-home,
.red-card-away {
  position: relative !important;
  margin: 0;
  min-width: 14px;
}

.live-match-teams-home,
.live-match-teams-away {
  display: flex;
  align-items: center;
  gap: 10px;
}

.live-match-teams-away {
  justify-content: end;
}

.match-title-cont-lv {
  justify-content: space-between;
}

.m-t.i-c-even {
  /* padding: 0 1vw !important; */
}
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-indicator .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #007bff;
  margin: 0 5px;
  animation: pulse 1.5s ease-in-out infinite;
}

.loading-indicator .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-indicator .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}
.bingo-ticket-header {
  background-color: #333;
  color: white;
  padding: 5px;
  margin-bottom: 5px;
  display: block;
    width: 100%;
}

.bingo-ticket-info {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

.bingo-ticket-info span {
  font-size: 12px;
}