@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-BlackItalic.eot');
    src: url('./Roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-BlackItalic.woff2') format('woff2'),
        url('./Roboto/Roboto-BlackItalic.woff') format('woff'),
        url('./Roboto/Roboto-BlackItalic.ttf') format('truetype'),
        url('./Roboto/Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Light.eot');
    src: url('./Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-Light.woff2') format('woff2'),
        url('./Roboto/Roboto-Light.woff') format('woff'),
        url('./Roboto/Roboto-Light.ttf') format('truetype'),
        url('./Roboto/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-BoldItalic.eot');
    src: url('./Roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-BoldItalic.woff2') format('woff2'),
        url('./Roboto/Roboto-BoldItalic.woff') format('woff'),
        url('./Roboto/Roboto-BoldItalic.ttf') format('truetype'),
        url('./Roboto/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Bold.eot');
    src: url('./Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-Bold.woff2') format('woff2'),
        url('./Roboto/Roboto-Bold.woff') format('woff'),
        url('./Roboto/Roboto-Bold.ttf') format('truetype'),
        url('./Roboto/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Black.eot');
    src: url('./Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-Black.woff2') format('woff2'),
        url('./Roboto/Roboto-Black.woff') format('woff'),
        url('./Roboto/Roboto-Black.ttf') format('truetype'),
        url('./Roboto/Roboto-Black.svg#Roboto-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Italic.eot');
    src: url('./Roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-Italic.woff2') format('woff2'),
        url('./Roboto/Roboto-Italic.woff') format('woff'),
        url('./Roboto/Roboto-Italic.ttf') format('truetype'),
        url('./Roboto/Roboto-Italic.svg#Roboto-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-MediumItalic.eot');
    src: url('./Roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-MediumItalic.woff2') format('woff2'),
        url('./Roboto/Roboto-MediumItalic.woff') format('woff'),
        url('./Roboto/Roboto-MediumItalic.ttf') format('truetype'),
        url('./Roboto/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Thin.eot');
    src: url('./Roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-Thin.woff2') format('woff2'),
        url('./Roboto/Roboto-Thin.woff') format('woff'),
        url('./Roboto/Roboto-Thin.ttf') format('truetype'),
        url('./Roboto/Roboto-Thin.svg#Roboto-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Regular.eot');
    src: url('./Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-Regular.woff2') format('woff2'),
        url('./Roboto/Roboto-Regular.woff') format('woff'),
        url('./Roboto/Roboto-Regular.ttf') format('truetype'),
        url('./Roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-LightItalic.eot');
    src: url('./Roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-LightItalic.woff2') format('woff2'),
        url('./Roboto/Roboto-LightItalic.woff') format('woff'),
        url('./Roboto/Roboto-LightItalic.ttf') format('truetype'),
        url('./Roboto/Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Medium.eot');
    src: url('./Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-Medium.woff2') format('woff2'),
        url('./Roboto/Roboto-Medium.woff') format('woff'),
        url('./Roboto/Roboto-Medium.ttf') format('truetype'),
        url('./Roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-ThinItalic.eot');
    src: url('./Roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto/Roboto-ThinItalic.woff2') format('woff2'),
        url('./Roboto/Roboto-ThinItalic.woff') format('woff'),
        url('./Roboto/Roboto-ThinItalic.ttf') format('truetype'),
        url('./Roboto/Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-Bold.eot');
    src: url('./Roboto_Condensed/RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Roboto_Condensed/RobotoCondensed-Bold.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-Bold.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-Bold.ttf') format('truetype'),
        url('./Roboto_Condensed/RobotoCondensed-Bold.svg#RobotoCondensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-Light.eot');
    src: url('./Roboto_Condensed/RobotoCondensed-Light.eot?#iefix') format('embedded-opentype'),
        url('./Roboto_Condensed/RobotoCondensed-Light.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-Light.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-Light.ttf') format('truetype'),
        url('./Roboto_Condensed/RobotoCondensed-Light.svg#RobotoCondensed-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-BoldItalic.eot');
    src: url('./Roboto_Condensed/RobotoCondensed-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto_Condensed/RobotoCondensed-BoldItalic.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-BoldItalic.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-BoldItalic.ttf') format('truetype'),
        url('./Roboto_Condensed/RobotoCondensed-BoldItalic.svg#RobotoCondensed-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-LightItalic.eot');
    src: url('./Roboto_Condensed/RobotoCondensed-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto_Condensed/RobotoCondensed-LightItalic.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-LightItalic.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-LightItalic.ttf') format('truetype'),
        url('./Roboto_Condensed/RobotoCondensed-LightItalic.svg#RobotoCondensed-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-Italic.eot');
    src: url('./Roboto_Condensed/RobotoCondensed-Italic.eot?#iefix') format('embedded-opentype'),
        url('./Roboto_Condensed/RobotoCondensed-Italic.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-Italic.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-Italic.ttf') format('truetype'),
        url('./Roboto_Condensed/RobotoCondensed-Italic.svg#RobotoCondensed-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./Roboto_Condensed/RobotoCondensed-Regular.eot');
    src: url('./Roboto_Condensed/RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Roboto_Condensed/RobotoCondensed-Regular.woff2') format('woff2'),
        url('./Roboto_Condensed/RobotoCondensed-Regular.woff') format('woff'),
        url('./Roboto_Condensed/RobotoCondensed-Regular.ttf') format('truetype'),
        url('./Roboto_Condensed/RobotoCondensed-Regular.svg#RobotoCondensed-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-Black.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-Black.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-Black.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-Black.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-BlackItalic.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-BlackItalic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-BlackItalic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-BlackItalic.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-BlackItalic.svg#SourceSansPro-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-ExtraLightItalic.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLightItalic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLightItalic.svg#SourceSansPro-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-Italic.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-Italic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-Italic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-Italic.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-Italic.svg#SourceSansPro-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-BoldItalic.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-BoldItalic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-BoldItalic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-BoldItalic.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-BoldItalic.svg#SourceSansPro-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-Bold.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-Bold.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-Bold.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-Bold.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-ExtraLight.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLight.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLight.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLight.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-Light.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-Light.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-Light.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-Light.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-LightItalic.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-LightItalic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-LightItalic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-LightItalic.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-LightItalic.svg#SourceSansPro-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-SemiBold.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBold.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBold.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBold.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-Regular.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-Regular.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-Regular.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./Source_Sans_Pro/SourceSansPro-SemiBoldItalic.eot');
    src: url('./Source_Sans_Pro/SourceSansPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBoldItalic.woff') format('woff'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf') format('truetype'),
        url('./Source_Sans_Pro/SourceSansPro-SemiBoldItalic.svg#SourceSansPro-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('./Alumni_Sans/AlumniSans-Light.eot');
    src: url('./Alumni_Sans/AlumniSans-Light.eot?#iefix') format('embedded-opentype'),
        url('./Alumni_Sans/AlumniSans-Light.woff2') format('woff2'),
        url('./Alumni_Sans/AlumniSans-Light.woff') format('woff'),
        url('./Alumni_Sans/AlumniSans-Light.ttf') format('truetype'),
        url('./Alumni_Sans/AlumniSans-Light.svg#AlumniSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('./Alumni_Sans/AlumniSans-Bold.eot');
    src: url('./Alumni_Sans/AlumniSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Alumni_Sans/AlumniSans-Bold.woff2') format('woff2'),
        url('./Alumni_Sans/AlumniSans-Bold.woff') format('woff'),
        url('./Alumni_Sans/AlumniSans-Bold.ttf') format('truetype'),
        url('./Alumni_Sans/AlumniSans-Bold.svg#AlumniSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('./Alumni_Sans/AlumniSans-Regular.eot');
    src: url('./Alumni_Sans/AlumniSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Alumni_Sans/AlumniSans-Regular.woff2') format('woff2'),
        url('./Alumni_Sans/AlumniSans-Regular.woff') format('woff'),
        url('./Alumni_Sans/AlumniSans-Regular.ttf') format('truetype'),
        url('./Alumni_Sans/AlumniSans-Regular.svg#AlumniSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('./Alumni_Sans/AlumniSans-SemiBold.eot');
    src: url('./Alumni_Sans/AlumniSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./Alumni_Sans/AlumniSans-SemiBold.woff2') format('woff2'),
        url('./Alumni_Sans/AlumniSans-SemiBold.woff') format('woff'),
        url('./Alumni_Sans/AlumniSans-SemiBold.ttf') format('truetype'),
        url('./Alumni_Sans/AlumniSans-SemiBold.svg#AlumniSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('./Alumni_Sans/AlumniSans-Medium.eot');
    src: url('./Alumni_Sans/AlumniSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Alumni_Sans/AlumniSans-Medium.woff2') format('woff2'),
        url('./Alumni_Sans/AlumniSans-Medium.woff') format('woff'),
        url('./Alumni_Sans/AlumniSans-Medium.ttf') format('truetype'),
        url('./Alumni_Sans/AlumniSans-Medium.svg#AlumniSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

