.t-t[data-col="8"][data-box="3"] {
  width: calc(100% - 8px - calc(3 * var(--button-w)));
}

.t-t[data-col="8"][data-box="4"] {
  width: calc(100% - 8px - calc(4 * var(--button-w)));
}

.t-t[data-col="8"][data-box="5"] {
  width: calc(100% - 8px - calc(5 * var(--button-w)));
}

.t-t[data-col="8"][data-box="6"] {
  width: calc(100% - 8px - calc(6 * var(--button-w)));
}

.t-t[data-col="8"][data-box="7"] {
  width: calc(100% - 8px - calc(7 * var(--button-w)));
}

.t-t[data-col="8"][data-box="8"] {
  width: calc(100% - 8px - calc(8 * var(--button-w)));
}

.t-t[data-col="8"][data-box="9"] {
  width: calc(100% - 8px - calc(9 * var(--button-w)));
}

.t-t[data-col="8"][data-box="10"] {
  width: calc(100% - 8px - calc(10 * var(--button-w)));
}

.t-t[data-col="8"][data-box="11"] {
  width: calc(100% - 8px - calc(11 * var(--button-w)));
}

.t-t[data-col="8"][data-box="12"] {
  width: calc(100% - 8px - calc(12 * var(--button-w)));
}

.t-t[data-col="8"][data-box="13"] {
  width: calc(100% - 8px - calc(13 * var(--button-w)));
}

.t-t[data-col="8"][data-box="14"] {
  width: calc(100% - 8px - calc(14 * var(--button-w)));
}

.t-t[data-col="8"][data-box="15"] {
  width: calc(100% - 8px - calc(15 * var(--button-w)));
}

.t-t[data-col="8"][data-box="16"] {
  width: calc(100% - 8px - calc(16 * var(--button-w)));
}

.t-t[data-col="8"][data-box="17"] {
  width: calc(100% - 8px - calc(17 * var(--button-w)));
}

.t-t[data-col="8"][data-box="18"] {
  width: calc(100% - 8px - calc(18 * var(--button-w)));
}

/****** */

.t-t[data-col="7"][data-box="3"] {
  width: calc(100% - 7px - calc(3 * var(--button-w)));
}

.t-t[data-col="7"][data-box="4"] {
  width: calc(100% - 7px - calc(4 * var(--button-w)));
}

.t-t[data-col="7"][data-box="5"] {
  width: calc(100% - 7px - calc(5 * var(--button-w)));
}

.t-t[data-col="7"][data-box="6"] {
  width: calc(100% - 7px - calc(6 * var(--button-w)));
}

.t-t[data-col="7"][data-box="7"] {
  width: calc(100% - 7px - calc(7 * var(--button-w)));
}

.t-t[data-col="7"][data-box="8"] {
  width: calc(100% - 7px - calc(8 * var(--button-w)));
}

.t-t[data-col="7"][data-box="9"] {
  width: calc(100% - 7px - calc(9 * var(--button-w)));
}

.t-t[data-col="7"][data-box="10"] {
  width: calc(100% - 7px - calc(10 * var(--button-w)));
}

.t-t[data-col="7"][data-box="11"] {
  width: calc(100% - 7px - calc(11 * var(--button-w)));
}

.t-t[data-col="7"][data-box="12"] {
  width: calc(100% - 7px - calc(12 * var(--button-w)));
}

.t-t[data-col="7"][data-box="13"] {
  width: calc(100% - 7px - calc(13 * var(--button-w)));
}

.t-t[data-col="7"][data-box="14"] {
  width: calc(100% - 7px - calc(14 * var(--button-w)));
}

.t-t[data-col="7"][data-box="15"] {
  width: calc(100% - 7px - calc(15 * var(--button-w)));
}

.t-t[data-col="7"][data-box="16"] {
  width: calc(100% - 7px - calc(16 * var(--button-w)));
}

.t-t[data-col="7"][data-box="17"] {
  width: calc(100% - 10px - calc(17 * var(--button-w)));
}

.t-t[data-col="7"][data-box="18"] {
  width: calc(100% - 7px - calc(18 * var(--button-w)));
}

/****** */

.t-t[data-col="6"][data-box="3"] {
  width: calc(100% - 6px - calc(3 * var(--button-w)));
}

.t-t[data-col="6"][data-box="4"] {
  width: calc(100% - 6px - calc(4 * var(--button-w)));
}

.t-t[data-col="6"][data-box="5"] {
  width: calc(100% - 6px - calc(5 * var(--button-w)));
}

.t-t[data-col="6"][data-box="6"] {
  width: calc(100% - 6px - calc(6 * var(--button-w)));
}

.t-t[data-col="6"][data-box="7"] {
  width: calc(100% - 6px - calc(7 * var(--button-w)));
}

.t-t[data-col="6"][data-box="8"] {
  width: calc(100% - 6px - calc(8 * var(--button-w)));
}

.t-t[data-col="6"][data-box="9"] {
  width: calc(100% - 6px - calc(9 * var(--button-w)));
}

.t-t[data-col="6"][data-box="10"] {
  width: calc(100% - 6px - calc(10 * var(--button-w)));
}

.t-t[data-col="6"][data-box="11"] {
  width: calc(100% - 6px - calc(11 * var(--button-w)));
}

.t-t[data-col="6"][data-box="12"] {
  width: calc(100% - 6px - calc(12 * var(--button-w)));
}

.t-t[data-col="6"][data-box="13"] {
  width: calc(100% - 6px - calc(13 * var(--button-w)));
}

.t-t[data-col="6"][data-box="14"] {
  width: calc(100% - 6px - calc(14 * var(--button-w)));
}

.t-t[data-col="6"][data-box="15"] {
  width: calc(100% - 6px - calc(15 * var(--button-w)));
}

.t-t[data-col="6"][data-box="16"] {
  width: calc(100% - 6px - calc(16 * var(--button-w)));
}

.t-t[data-col="6"][data-box="17"] {
  width: calc(100% - 6px - calc(17 * var(--button-w)));
}

.t-t[data-col="6"][data-box="18"] {
  width: calc(100% - 6px - calc(18 * var(--button-w)));
}

/****** */

.t-t[data-col="5"][data-box="3"] {
  width: calc(100% - 5px - calc(3 * var(--button-w)));
}

.t-t[data-col="5"][data-box="4"] {
  width: calc(100% - 5px - calc(4 * var(--button-w)));
}

.t-t[data-col="5"][data-box="5"] {
  width: calc(100% - 5px - calc(5 * var(--button-w)));
}

.t-t[data-col="5"][data-box="6"] {
  width: calc(100% - 5px - calc(6 * var(--button-w)));
}

.t-t[data-col="5"][data-box="7"] {
  width: calc(100% - 5px - calc(7 * var(--button-w)));
}

.t-t[data-col="5"][data-box="8"] {
  width: calc(100% - 5px - calc(8 * var(--button-w)));
}

.t-t[data-col="5"][data-box="9"] {
  width: calc(100% - 5px - calc(9 * var(--button-w)));
}

.t-t[data-col="5"][data-box="10"] {
  width: calc(100% - 5px - calc(10 * var(--button-w)));
}

.t-t[data-col="5"][data-box="11"] {
  width: calc(100% - 5px - calc(11 * var(--button-w)));
}

.t-t[data-col="5"][data-box="12"] {
  width: calc(100% - 5px - calc(12 * var(--button-w)));
}

.t-t[data-col="5"][data-box="13"] {
  width: calc(100% - 5px - calc(13 * var(--button-w)));
}

.t-t[data-col="5"][data-box="14"] {
  width: calc(100% - 5px - calc(14 * var(--button-w)));
}

.t-t[data-col="5"][data-box="15"] {
  width: calc(99% - 16px - calc(15 * var(--button-w)));
}

.t-t[data-col="5"][data-box="16"] {
  width: calc(100% - 5px - calc(16 * var(--button-w)));
}

.t-t[data-col="5"][data-box="17"] {
  width: calc(100% - 5px - calc(17 * var(--button-w)));
}

.t-t[data-col="5"][data-box="18"] {
  width: calc(100% - 5px - calc(18 * var(--button-w)));
}

/****** */

.t-t[data-col="4"][data-box="3"] {
  width: calc(100% - 4px - calc(3 * var(--button-w)));
}

.t-t[data-col="4"][data-box="4"] {
  width: calc(100% - 4px - calc(4 * var(--button-w)));
}

.t-t[data-col="4"][data-box="5"] {
  width: calc(100% - 4px - calc(5 * var(--button-w)));
}

.t-t[data-col="4"][data-box="6"] {
  width: calc(100% - 4px - calc(6 * var(--button-w)));
}

.t-t[data-col="4"][data-box="7"] {
  width: calc(100% - 4px - calc(7 * var(--button-w)));
}

.t-t[data-col="4"][data-box="8"] {
  width: calc(100% - 4px - calc(8 * var(--button-w)));
}

.t-t[data-col="4"][data-box="9"] {
  width: calc(100% - 4px - calc(9 * var(--button-w)));
}

.t-t[data-col="4"][data-box="10"] {
  width: calc(100% - 4px - calc(10 * var(--button-w)));
}

.t-t[data-col="4"][data-box="11"] {
  width: calc(100% - 4px - calc(11 * var(--button-w)));
}

.t-t[data-col="4"][data-box="12"] {
  width: calc(100% - 4px - calc(12 * var(--button-w)));
}

.t-t[data-col="4"][data-box="13"] {
  width: calc(100% - 4px - calc(13 * var(--button-w)));
}

.t-t[data-col="4"][data-box="14"] {
  width: calc(100% - 4px - calc(14 * var(--button-w)));
}

.t-t[data-col="4"][data-box="15"] {
  width: calc(100% - 4px - calc(15 * var(--button-w)));
}

.t-t[data-col="4"][data-box="16"] {
  width: calc(100% - 4px - calc(16 * var(--button-w)));
}

.t-t[data-col="4"][data-box="17"] {
  width: calc(100% - 4px - calc(17 * var(--button-w)));
}

.t-t[data-col="4"][data-box="18"] {
  width: calc(100% - 4px - calc(18 * var(--button-w)));
}

/****** */

.t-t[data-col="3"][data-box="3"] {
  width: calc(100% - 3px - calc(3 * var(--button-w)));
}

.t-t[data-col="3"][data-box="4"] {
  width: calc(100% - 3px - calc(4 * var(--button-w)));
}

.t-t[data-col="3"][data-box="5"] {
  width: calc(100% - 3px - calc(5 * var(--button-w)));
}

.t-t[data-col="3"][data-box="6"] {
  width: calc(100% - 3px - calc(6 * var(--button-w)));
}

.t-t[data-col="3"][data-box="7"] {
  width: calc(100% - 3px - calc(7 * var(--button-w)));
}

.t-t[data-col="3"][data-box="8"] {
  width: calc(100% - 3px - calc(8 * var(--button-w)));
}

.t-t[data-col="3"][data-box="9"] {
  width: calc(100% - 3px - calc(9 * var(--button-w)));
}

.t-t[data-col="3"][data-box="10"] {
  width: calc(100% - 3px - calc(10 * var(--button-w)));
}

.t-t[data-col="3"][data-box="11"] {
  width: calc(100% - 3px - calc(11 * var(--button-w)));
}

.t-t[data-col="3"][data-box="12"] {
  width: calc(100% - 3px - calc(12 * var(--button-w)));
}

.t-t[data-col="3"][data-box="13"] {
  width: calc(100% - 3px - calc(13 * var(--button-w)));
}

.t-t[data-col="3"][data-box="14"] {
  width: calc(100% - 3px - calc(14 * var(--button-w)));
}

.t-t[data-col="3"][data-box="15"] {
  width: calc(100% - 3px - calc(15 * var(--button-w)));
}

.t-t[data-col="3"][data-box="16"] {
  width: calc(100% - 3px - calc(16 * var(--button-w)));
}

.t-t[data-col="3"][data-box="17"] {
  width: calc(100% - 3px - calc(17 * var(--button-w)));
}

.t-t[data-col="3"][data-box="18"] {
  width: calc(100% - 3px - calc(18 * var(--button-w)));
}

/****** */

.t-t[data-col="2"][data-box="2"] {
  width: calc(100% - 2px - calc(2 * var(--button-w)));
}

.t-t[data-col="2"][data-box="3"] {
  width: calc(100% - 2px - calc(3 * var(--button-w)));
}

.t-t[data-col="2"][data-box="4"] {
  width: calc(100% - 2px - calc(4 * var(--button-w)));
}

.t-t[data-col="2"][data-box="5"] {
  width: calc(100% - 2px - calc(5 * var(--button-w)));
}

.t-t[data-col="2"][data-box="6"] {
  width: calc(100% - 2px - calc(6 * var(--button-w)));
}

.t-t[data-col="2"][data-box="7"] {
  width: calc(100% - 2px - calc(7 * var(--button-w)));
}

.t-t[data-col="2"][data-box="8"] {
  width: calc(100% - 2px - calc(8 * var(--button-w)));
}

.t-t[data-col="2"][data-box="9"] {
  width: calc(100% - 2px - calc(9 * var(--button-w)));
  width: calc(100% - 2px - calc(9 * var(--button-w)) + 2px);
}

.t-t[data-col="2"][data-box="10"] {
  width: calc(100% - 2px - calc(10 * var(--button-w)));
}

.t-t[data-col="2"][data-box="11"] {
  width: calc(100% - 2px - calc(11 * var(--button-w)));
}

.t-t[data-col="2"][data-box="12"] {
  width: calc(100% - 2px - calc(12 * var(--button-w)));
}

.t-t[data-col="2"][data-box="13"] {
  width: calc(100% - 2px - calc(13 * var(--button-w)));
}

.t-t[data-col="2"][data-box="14"] {
  width: calc(100% - 2px - calc(14 * var(--button-w)));
}

.t-t[data-col="2"][data-box="15"] {
  width: calc(100% - 2px - calc(15 * var(--button-w)));
}

.t-t[data-col="2"][data-box="16"] {
  width: calc(100% - 2px - calc(16 * var(--button-w)));
}

.t-t[data-col="2"][data-box="17"] {
  width: calc(100% - 2px - calc(17 * var(--button-w)));
}

.t-t[data-col="2"][data-box="18"] {
  width: calc(100% - 2px - calc(18 * var(--button-w)));
}

/* ********************** */

.m-t[data-col="8"][data-box="3"] {
  width: calc(100% - 8px - calc(3 * var(--button-w)));
}

.m-t[data-col="8"][data-box="4"] {
  width: calc(100% - 8px - calc(4 * var(--button-w)));
}

.m-t[data-col="8"][data-box="5"] {
  width: calc(100% - 8px - calc(5 * var(--button-w)));
}

.m-t[data-col="8"][data-box="6"] {
  width: calc(100% - 8px - calc(6 * var(--button-w)));
}

.m-t[data-col="8"][data-box="7"] {
  width: calc(100% - 8px - calc(7 * var(--button-w)));
}

.m-t[data-col="8"][data-box="8"] {
  width: calc(100% - 8px - calc(8 * var(--button-w)));
}

.m-t[data-col="8"][data-box="9"] {
  width: calc(100% - 8px - calc(9 * var(--button-w)));
}

.m-t[data-col="8"][data-box="10"] {
  width: calc(100% - 8px - calc(10 * var(--button-w)));
}

.m-t[data-col="8"][data-box="11"] {
  width: calc(100% - 8px - calc(11 * var(--button-w)));
}

.m-t[data-col="8"][data-box="12"] {
  width: calc(100% - 8px - calc(12 * var(--button-w)));
}

.m-t[data-col="8"][data-box="13"] {
  width: calc(100% - 8px - calc(13 * var(--button-w)));
}

.m-t[data-col="8"][data-box="14"] {
  width: calc(100% - 8px - calc(14 * var(--button-w)));
}

.m-t[data-col="8"][data-box="15"] {
  width: calc(100% - 8px - calc(15 * var(--button-w)));
}

.m-t[data-col="8"][data-box="16"] {
  width: calc(100% - 8px - calc(16 * var(--button-w)));
}

.m-t[data-col="8"][data-box="17"] {
  width: calc(100% - 8px - calc(17 * var(--button-w)));
}

.m-t[data-col="8"][data-box="18"] {
  width: calc(100% - 8px - calc(18 * var(--button-w)));
}

/* ------------------------------ */

.m-t[data-col="7"][data-box="3"] {
  width: calc(100% - 7px - calc(3 * var(--button-w)));
}

.m-t[data-col="7"][data-box="4"] {
  width: calc(100% - 7px - calc(4 * var(--button-w)));
}

.m-t[data-col="7"][data-box="5"] {
  width: calc(100% - 7px - calc(5 * var(--button-w)));
}

.m-t[data-col="7"][data-box="6"] {
  width: calc(100% - 7px - calc(6 * var(--button-w)));
}

.m-t[data-col="7"][data-box="7"] {
  width: calc(100% - 7px - calc(7 * var(--button-w)));
}

.m-t[data-col="7"][data-box="8"] {
  width: calc(100% - 7px - calc(8 * var(--button-w)));
}

.m-t[data-col="7"][data-box="9"] {
  width: calc(100% - 7px - calc(9 * var(--button-w)));
}

.m-t[data-col="7"][data-box="10"] {
  width: calc(100% - 7px - calc(10 * var(--button-w)));
}

.m-t[data-col="7"][data-box="11"] {
  width: calc(100% - 7px - calc(11 * var(--button-w)));
}

.m-t[data-col="7"][data-box="12"] {
  width: calc(100% - 7px - calc(12 * var(--button-w)));
}

.m-t[data-col="7"][data-box="13"] {
  width: calc(100% - 7px - calc(13 * var(--button-w)));
}

.m-t[data-col="7"][data-box="14"] {
  width: calc(100% - 7px - calc(14 * var(--button-w)));
}

.m-t[data-col="7"][data-box="15"] {
  width: calc(100% - 7px - calc(15 * var(--button-w)));
}

.m-t[data-col="7"][data-box="16"] {
  width: calc(100% - 7px - calc(16 * var(--button-w)));
}

.m-t[data-col="7"][data-box="17"] {
  width: calc(100% - 10px - calc(17 * var(--button-w)));
}

.m-t[data-col="7"][data-box="18"] {
  width: calc(100% - 7px - calc(18 * var(--button-w)));
}

/****** */

.m-t[data-col="6"][data-box="3"] {
  width: calc(100% - 7px - calc(3 * var(--button-w)));
}

.m-t[data-col="6"][data-box="4"] {
  width: calc(100% - 7px - calc(4 * var(--button-w)));
}

.m-t[data-col="6"][data-box="5"] {
  width: calc(100% - 7px - calc(5 * var(--button-w)));
}

.m-t[data-col="6"][data-box="6"] {
  width: calc(100% - 7px - calc(6 * var(--button-w)));
}

.m-t[data-col="6"][data-box="7"] {
  width: calc(100% - 7px - calc(7 * var(--button-w)));
}

.m-t[data-col="6"][data-box="8"] {
  width: calc(100% - 7px - calc(8 * var(--button-w)));
}

.m-t[data-col="6"][data-box="9"] {
  width: calc(100% - 7px - calc(9 * var(--button-w)));
}

.m-t[data-col="6"][data-box="10"] {
  width: calc(100% - 7px - calc(10 * var(--button-w)));
}

.m-t[data-col="6"][data-box="11"] {
  width: calc(100% - 6px - calc(11 * var(--button-w)));
}

.m-t[data-col="6"][data-box="12"] {
  width: calc(100% - 6px - calc(12 * var(--button-w)));
}

.m-t[data-col="6"][data-box="13"] {
  width: calc(100% - 6px - calc(13 * var(--button-w)));
}

.m-t[data-col="6"][data-box="14"] {
  width: calc(100% - 6px - calc(14 * var(--button-w)));
}

.m-t[data-col="6"][data-box="15"] {
  width: calc(100% - 6px - calc(15 * var(--button-w)));
}

.m-t[data-col="6"][data-box="16"] {
  width: calc(100% - 6px - calc(16 * var(--button-w)));
}

.m-t[data-col="6"][data-box="17"] {
  width: calc(100% - 6px - calc(17 * var(--button-w)));
}

.m-t[data-col="6"][data-box="18"] {
  width: calc(100% - 6px - calc(18 * var(--button-w)));
}

/****** */

.m-t[data-col="5"][data-box="3"] {
  width: calc(100% - 5px - calc(3 * var(--button-w)));
}

.m-t[data-col="5"][data-box="4"] {
  width: calc(100% - 5px - calc(4 * var(--button-w)));
}

.m-t[data-col="5"][data-box="5"] {
  width: calc(100% - 5px - calc(5 * var(--button-w)));
}

.m-t[data-col="5"][data-box="6"] {
  width: calc(100% - 5px - calc(6 * var(--button-w)));
}

.m-t[data-col="5"][data-box="7"] {
  width: calc(100% - 5px - calc(7 * var(--button-w)));
}

.m-t[data-col="5"][data-box="8"] {
  width: calc(100% - 5px - calc(8 * var(--button-w)));
}

.m-t[data-col="5"][data-box="9"] {
  width: calc(100% - 5px - calc(9 * var(--button-w)));
}

.m-t[data-col="5"][data-box="10"] {
  width: calc(100% - 5px - calc(10 * var(--button-w)));
}

.m-t[data-col="5"][data-box="11"] {
  width: calc(100% - 5px - calc(11 * var(--button-w)));
}

.m-t[data-col="5"][data-box="12"] {
  width: calc(100% - 5px - calc(12 * var(--button-w)));
}

.m-t[data-col="5"][data-box="13"] {
  width: calc(100% - 5px - calc(13 * var(--button-w)));
}

.m-t[data-col="5"][data-box="14"] {
  width: calc(100% - 5px - calc(14 * var(--button-w)));
}

.m-t[data-col="5"][data-box="15"] {
  width: calc(100% - 5px - calc(15 * var(--button-w)));
}

.m-t[data-col="5"][data-box="16"] {
  width: calc(100% - 5px - calc(16 * var(--button-w)));
}

.m-t[data-col="5"][data-box="17"] {
  width: calc(100% - 5px - calc(17 * var(--button-w)));
}

.m-t[data-col="5"][data-box="18"] {
  width: calc(100% - 5px - calc(18 * var(--button-w)));
}

/****** */

.m-t[data-col="4"][data-box="3"] {
  width: calc(100% - 4px - calc(3 * var(--button-w)));
}

.m-t[data-col="4"][data-box="4"] {
  width: calc(100% - 4px - calc(4 * var(--button-w)));
}

.m-t[data-col="4"][data-box="5"] {
  width: calc(100% - 4px - calc(5 * var(--button-w)));
}

.m-t[data-col="4"][data-box="6"] {
  width: calc(100% - 4px - calc(6 * var(--button-w)));
}

.m-t[data-col="4"][data-box="7"] {
  width: calc(100% - 4px - calc(7 * var(--button-w)));
}

.m-t[data-col="4"][data-box="8"] {
  width: calc(100% - 4px - calc(8 * var(--button-w)));
}

.m-t[data-col="4"][data-box="9"] {
  width: calc(100% - 4px - calc(9 * var(--button-w)));
}

.m-t[data-col="4"][data-box="10"] {
  width: calc(100% - 4px - calc(10 * var(--button-w)));
}

.m-t[data-col="4"][data-box="11"] {
  width: calc(100% - 4px - calc(11 * var(--button-w)));
}

.m-t[data-col="4"][data-box="12"] {
  width: calc(100% - 4px - calc(12 * var(--button-w)));
}

.m-t[data-col="4"][data-box="13"] {
  width: calc(100% - 4px - calc(13 * var(--button-w)));
}

.m-t[data-col="4"][data-box="14"] {
  width: calc(100% - 4px - calc(14 * var(--button-w)));
}

.m-t[data-col="4"][data-box="15"] {
  width: calc(100% - 4px - calc(15 * var(--button-w)));
}

.m-t[data-col="4"][data-box="16"] {
  width: calc(100% - 4px - calc(16 * var(--button-w)));
}

.m-t[data-col="4"][data-box="17"] {
  width: calc(100% - 4px - calc(17 * var(--button-w)));
}

.m-t[data-col="4"][data-box="18"] {
  width: calc(100% - 4px - calc(18 * var(--button-w)));
}

/****** */

.m-t[data-col="3"][data-box="3"] {
  width: calc(100% - 3px - calc(3 * var(--button-w)));
}

.m-t[data-col="3"][data-box="4"] {
  width: calc(100% - 3px - calc(4 * var(--button-w)));
}

.m-t[data-col="3"][data-box="5"] {
  width: calc(100% - 3px - calc(5 * var(--button-w)));
}

.m-t[data-col="3"][data-box="6"] {
  width: calc(100% - 3px - calc(6 * var(--button-w)));
}

.m-t[data-col="3"][data-box="7"] {
  width: calc(100% - 3px - calc(7 * var(--button-w)));
}

.m-t[data-col="3"][data-box="8"] {
  width: calc(100% - 3px - calc(8 * var(--button-w)));
}

.m-t[data-col="3"][data-box="9"] {
  width: calc(100% - 3px - calc(9 * var(--button-w)));
}

.m-t[data-col="3"][data-box="10"] {
  width: calc(100% - 3px - calc(10 * var(--button-w)));
}

.m-t[data-col="3"][data-box="11"] {
  width: calc(100% - 3px - calc(11 * var(--button-w)));
}

.m-t[data-col="3"][data-box="12"] {
  width: calc(100% - 3px - calc(12 * var(--button-w)));
}

.m-t[data-col="3"][data-box="13"] {
  width: calc(100% - 3px - calc(13 * var(--button-w)));
}

.m-t[data-col="3"][data-box="14"] {
  width: calc(100% - 3px - calc(14 * var(--button-w)));
}

.m-t[data-col="3"][data-box="15"] {
  width: calc(100% - 3px - calc(15 * var(--button-w)));
}

.m-t[data-col="3"][data-box="16"] {
  width: calc(100% - 3px - calc(16 * var(--button-w)));
}

.m-t[data-col="3"][data-box="17"] {
  width: calc(100% - 3px - calc(17 * var(--button-w)));
}

.m-t[data-col="3"][data-box="18"] {
  width: calc(100% - 3px - calc(18 * var(--button-w)));
}

/****** */

.m-t[data-col="2"][data-box="2"] {
  width: calc(100% - 2px - calc(2 * var(--button-w)));
}

.m-t[data-col="2"][data-box="3"] {
  width: calc(100% - 2px - calc(3 * var(--button-w)));
}

.m-t[data-col="2"][data-box="4"] {
  width: calc(100% - 2px - calc(4 * var(--button-w)));
}

.m-t[data-col="2"][data-box="5"] {
  width: calc(100% - 2px - calc(5 * var(--button-w)));
}

.m-t[data-col="2"][data-box="6"] {
  width: calc(100% - 2px - calc(6 * var(--button-w)));
}

.m-t[data-col="2"][data-box="7"] {
  width: calc(100% - 2px - calc(7 * var(--button-w)));
}

.m-t[data-col="2"][data-box="8"] {
  width: calc(100% - 2px - calc(8 * var(--button-w)));
}

.m-t[data-col="2"][data-box="9"] {
  width: calc(100% - 2px - calc(9 * var(--button-w)));
}

.m-t[data-col="2"][data-box="10"] {
  width: calc(100% - 2px - calc(10 * var(--button-w)));
}

.m-t[data-col="2"][data-box="11"] {
  width: calc(100% - 2px - calc(11 * var(--button-w)));
}

.m-t[data-col="2"][data-box="12"] {
  width: calc(100% - 2px - calc(12 * var(--button-w)));
}

.m-t[data-col="2"][data-box="13"] {
  width: calc(100% - 2px - calc(13 * var(--button-w)));
}

.m-t[data-col="2"][data-box="14"] {
  width: calc(100% - 2px - calc(14 * var(--button-w)));
}

.m-t[data-col="2"][data-box="15"] {
  width: calc(100% - 2px - calc(15 * var(--button-w)));
}

.m-t[data-col="2"][data-box="16"] {
  width: calc(100% - 2px - calc(16 * var(--button-w)));
}

.m-t[data-col="2"][data-box="17"] {
  width: calc(100% - 2px - calc(17 * var(--button-w)));
}

.m-t[data-col="2"][data-box="18"] {
  width: calc(100% - 2px - calc(18 * var(--button-w)));
}

/* -------------------------------- */

.m-t-2[data-col="7"][data-box="3"] {
  width: calc(100% - 7px - calc(3 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="4"] {
  width: calc(100% - 7px - calc(4 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="5"] {
  width: calc(100% - 7px - calc(5 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="6"] {
  width: calc(100% - 7px - calc(6 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="7"] {
  width: calc(100% - 7px - calc(7 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="8"] {
  width: calc(100% - 7px - calc(8 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="9"] {
  width: calc(100% - 7px - calc(9 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="10"] {
  width: calc(100% - 7px - calc(10 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="11"] {
  width: calc(100% - 7px - calc(11 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="12"] {
  width: calc(100% - 7px - calc(12 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="13"] {
  width: calc(100% - 7px - calc(13 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="14"] {
  width: calc(100% - 7px - calc(14 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="15"] {
  width: calc(100% - 7px - calc(15 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="16"] {
  width: calc(100% - 7px - calc(16 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="17"] {
  width: calc(100% - 10px - calc(17 * var(--button-w)));
}

.m-t-2[data-col="7"][data-box="18"] {
  width: calc(100% - 7px - calc(18 * var(--button-w)));
}

/****** */

.m-t-2[data-col="6"][data-box="3"] {
  width: calc(100% - 6px - calc(3 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="4"] {
  width: calc(100% - 6px - calc(4 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="5"] {
  width: calc(100% - 6px - calc(5 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="6"] {
  width: calc(100% - 6px - calc(6 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="7"] {
  width: calc(100% - 6px - calc(7 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="8"] {
  width: calc(100% - 6px - calc(8 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="9"] {
  width: calc(100% - 6px - calc(9 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="10"] {
  width: calc(100% - 6px - calc(10 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="11"] {
  width: calc(100% - 6px - calc(11 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="12"] {
  width: calc(100% - 6px - calc(12 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="13"] {
  width: calc(100% - 6px - calc(13 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="14"] {
  width: calc(100% - 6px - calc(14 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="15"] {
  width: calc(100% - 6px - calc(15 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="16"] {
  width: calc(100% - 6px - calc(16 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="17"] {
  width: calc(100% - 6px - calc(17 * var(--button-w)));
}

.m-t-2[data-col="6"][data-box="18"] {
  width: calc(100% - 6px - calc(18 * var(--button-w)));
}

/****** */

.m-t-2[data-col="5"][data-box="3"] {
  width: calc(100% - 5px - calc(3 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="4"] {
  width: calc(100% - 5px - calc(4 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="5"] {
  width: calc(100% - 5px - calc(5 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="6"] {
  width: calc(100% - 5px - calc(6 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="7"] {
  width: calc(100% - 5px - calc(7 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="8"] {
  width: calc(100% - 5px - calc(8 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="9"] {
  width: calc(100% - 5px - calc(9 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="10"] {
  width: calc(100% - 5px - calc(10 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="11"] {
  width: calc(100% - 5px - calc(11 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="12"] {
  width: calc(100% - 5px - calc(12 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="13"] {
  width: calc(100% - 5px - calc(13 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="14"] {
  width: calc(100% - 5px - calc(14 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="15"] {
  width: calc(100% - 5px - calc(15 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="16"] {
  width: calc(100% - 5px - calc(16 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="17"] {
  width: calc(100% - 5px - calc(17 * var(--button-w)));
}

.m-t-2[data-col="5"][data-box="18"] {
  width: calc(100% - 5px - calc(18 * var(--button-w)));
}

/****** */

.m-t-2[data-col="4"][data-box="3"] {
  width: calc(100% - 4px - calc(3 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="4"] {
  width: calc(100% - 4px - calc(4 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="5"] {
  width: calc(100% - 4px - calc(5 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="6"] {
  width: calc(100% - 4px - calc(6 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="7"] {
  width: calc(100% - 4px - calc(7 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="8"] {
  width: calc(100% - 4px - calc(8 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="9"] {
  width: calc(100% - 4px - calc(9 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="10"] {
  width: calc(100% - 4px - calc(10 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="11"] {
  width: calc(100% - 4px - calc(11 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="12"] {
  width: calc(100% - 4px - calc(12 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="13"] {
  width: calc(100% - 4px - calc(13 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="14"] {
  width: calc(100% - 4px - calc(14 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="15"] {
  width: calc(100% - 4px - calc(15 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="16"] {
  width: calc(100% - 4px - calc(16 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="17"] {
  width: calc(100% - 4px - calc(17 * var(--button-w)));
}

.m-t-2[data-col="4"][data-box="18"] {
  width: calc(100% - 4px - calc(18 * var(--button-w)));
}

/****** */

.m-t-2[data-col="3"][data-box="3"] {
  width: calc(100% - 3px - calc(3 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="4"] {
  width: calc(100% - 3px - calc(4 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="5"] {
  width: calc(100% - 3px - calc(5 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="6"] {
  width: calc(100% - 3px - calc(6 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="7"] {
  width: calc(100% - 3px - calc(7 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="8"] {
  width: calc(100% - 3px - calc(8 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="9"] {
  width: calc(100% - 3px - calc(9 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="10"] {
  width: calc(100% - 3px - calc(10 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="11"] {
  width: calc(100% - 3px - calc(11 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="12"] {
  width: calc(100% - 3px - calc(12 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="13"] {
  width: calc(100% - 3px - calc(13 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="14"] {
  width: calc(100% - 3px - calc(14 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="15"] {
  width: calc(100% - 3px - calc(15 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="16"] {
  width: calc(100% - 3px - calc(16 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="17"] {
  width: calc(100% - 3px - calc(17 * var(--button-w)));
}

.m-t-2[data-col="3"][data-box="18"] {
  width: calc(100% - 3px - calc(18 * var(--button-w)));
}
